import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Link, withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import HomePageMenu from '../../../shared/homepage-menu';

const logo = require('../../../../www/assets/icons/dfflogo.svg');

// const logo = require('../images/logo.svg');

class HeaderComponent extends Component<any, any> {

  renderHome = (itemProps: any) => <Link to='/' {...itemProps} />
  renderProcess = (itemProps: any) => <Link to='/process' {...itemProps} />
  renderContact = (itemProps: any) => <Link to='/contactus' {...itemProps} />
  renderTeam = (itemProps: any) => <Link to='/team' {...itemProps} />
  constructor(props: any) {
    super(props);
  }

  state = {
    value: 0,
    menuDrawer: false
  };

  handleChange = (event: any, value: any) => {
    this.setState({ value });
  };

  mobileMenuOpen = (event: any) => {
    this.setState({ menuDrawer: true });
  }

  mobileMenuClose = (event: any) => {
    this.setState({ menuDrawer: false });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  current = () => {
    if (this.props.location.pathname === '/home') {
      return 0
    }
    if (this.props.location.pathname === '/process') {
      return 1
    }
    if (this.props.location.pathname === '/contactus') {
      return 2
    }
    if (this.props.location.pathname === '/team') {
      return 3
    }
    // if (this.props.currentPath === '/cards') {
    //   return 4
    // }

  }

  render() {

    const { classes } = this.props;

    return (
      <AppBar position="fixed" color="default" className={classes.appBar} style={{ position: 'fixed', boxShadow: '0 1px 5px 0 rgba(0,0,0,.33)' }}>
        <Toolbar>
          <div style={{ width: '100%' }}>
            <Grid container direction="row" spacing={24} alignItems="center" justify="center">
              <Grid item xs={12} className={classes.flex}>
                <div className={classes.inline} style={{ minWidth: '25%', marginTop: 12 }}>
                  <Typography variant="h6" color="inherit" noWrap>
                    <Link to='/' className={classes.link}>
                      <img width={240} src={logo} alt="" />
                      {/* <span className={classes.tagline}>LOGO</span> */}
                    </Link>
                  </Typography>
                </div>

                <React.Fragment>
                  <div className={classes.productLogo} style={{ height: 40 }}>
                    {/* <Typography color="primary" variant="h4" style={{ fontWeight: 100 }}>
                      DFF Technologies
                      </Typography> */}
                  </div>
                  <div className={classes.iconContainer}>
                    <IconButton onClick={this.mobileMenuOpen} className={classes.iconButton} color="inherit" aria-label="Menu">
                      <MenuIcon />
                    </IconButton>
                  </div>
                  <div className={classes.tabContainer}>
                    <SwipeableDrawer anchor="right" open={this.state.menuDrawer} onClose={this.mobileMenuClose} onOpen={this.mobileMenuOpen}>
                      <AppBar title="Menu" />
                      <List>
                        {/* {HomePageMenu.map((item, index) => (
                          <ListItem component={this.renderDashboard} button key={item.label}>
                            <ListItemText primary={item.label} />
                          </ListItem>
                        ))} */}
                        <ListItem component={this.renderHome} button key="Home">
                          <ListItemText primary="Home" />
                        </ListItem>
                        <ListItem component={this.renderProcess} button key="Approach">
                          <ListItemText primary="Approach" />
                        </ListItem>
                        <ListItem component={this.renderContact} button key="Contact">
                          <ListItemText primary="Contact" />
                        </ListItem>
                        <ListItem component={this.renderTeam} button key="Team">
                          <ListItemText primary="Team" />
                        </ListItem>
                      </List>
                    </SwipeableDrawer>
                    <Tabs
                      value={this.current() || this.state.value}
                      indicatorColor="primary"
                      textColor="primary"
                      onChange={this.handleChange}
                    >
                      {/* {HomePageMenu.map((item, index) => (
                        <Tab component={this.renderDashboard} 
                             key={index} 
                             classes={{ root: classes.tabItem }} 
                             label={item.label} />
                      ))} */}
                      <Tab component={this.renderHome}
                        key={0}
                        classes={{ root: classes.tabItem }}
                        label="Home"
                      />
                      <Tab component={this.renderProcess}
                        key={1}
                        classes={{ root: classes.tabItem }}
                        label="Approach"
                      />
                      <Tab component={this.renderContact}
                        key={2}
                        classes={{ root: classes.tabItem }}
                        label="Contact"
                      />
                      <Tab component={this.renderTeam}
                        key={3}
                        classes={{ root: classes.tabItem }}
                        label="Team"
                      />
                    </Tabs>
                  </div>
                </React.Fragment>

              </Grid>
            </Grid>
          </div>
        </Toolbar>
      </AppBar>

    )
  }
}

const styles: any = (theme: any) => ({
  appBar: {
    position: 'relative',
    boxShadow: 'none',
    borderBottom: `2px solid ${theme.palette.grey['100']}`,
    backgroundColor: 'white',

  },
  inline: {
    display: 'inline'
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-evenly',
      alignItems: 'center'
    }
  },
  link: {
    textDecoration: 'none',
    color: 'inherit'
  },
  productLogo: {
    display: 'inline-block',
    borderLeft: `1px solid ${theme.palette.grey['A100']}`,
    marginLeft: 32,
    paddingLeft: 24,
    [theme.breakpoints.up('md')]: {
      paddingTop: '1.5em'
    }
  },
  tagline: {
    display: 'inline-block',
    marginLeft: 10,
    [theme.breakpoints.up('md')]: {
      paddingTop: '0.8em'
    }
  },
  iconContainer: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    }
  },
  iconButton: {
    float: 'right'
  },
  tabContainer: {
    marginLeft: 200,
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  tabItem: {
    paddingTop: 20,
    paddingBottom: 20,
    minWidth: 'auto',
    fontSize: 18
  }
})


export default withStyles(styles)(HeaderComponent);
