import { CssBaseline } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { withStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import { Component, default as React } from "react";
import 'react-vertical-timeline-component/style.min.css';
import HomePageFooterComponent from '../home/homepage-footer';
import HomePageMenu from '../home/homepage-header';
import "./teamcomponent.scss";
const avatar = require("../../../../www/assets/images/gandhi.png");

const backgroundShape = require('../../../images/shape.svg');
const gandhi = require('../../../../www/assets/images/gandhi.png');
const webIcon = require('../../../../www/assets/icons/web.png');
const mobileIcon = require('../../../../www/assets/icons/mobile.png');

class TeamComponent extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {

    }
  }

  componentDidUpdate(prevProps: any, prevState: any) {

  }

  render() {

    const { classes } = this.props;
    return (
      <React.Fragment>
        <CssBaseline />
        <HomePageMenu {...this.props} />
        <div style={{ marginTop: 40 }}></div>
        <div className={classes.root}>
          <Grid container direction="row" justify="center">
            <Grid spacing={24} alignItems="center" justify="center" container className={classes.grid}>
              <Grid container item xs={12} >

                <Grid item xs={12} style={{ height: 360, marginTop: 90 }}>
                  <div style={{ textAlign: 'center' }} className={classes.sample}>
                    {/* <Typography variant="h3" gutterBottom className={classes.marginbottom72}>
                      Our Services | What we do
                  </Typography> */}

                    <Typography variant="h1" gutterBottom style={{ color: '#ec2786', fontFamily: "'Montserrat', sans-serif", marginBottom: 30, fontSize: 90, fontWeight: 700 }}>
                      Our Team
                  </Typography>

                    <Typography style={{ fontFamily: 'Montserrat', fontSize: 20, fontWeight: 300, paddingTop: 40 }} gutterBottom>
                      We are a small team with designers and developers who has good experience in open source technologies.
                      <br />Teams are guided by people who has lot of hands on experience in IT Industry.
                  </Typography>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container direction="row" justify="center">
            <Grid spacing={40} alignItems="center" justify="center" container className={classes.grid4}>
              <Grid item xs={12} md={4}>
                <div className={classes.paper}>
                  <div>
                    {/* <Typography variant="h5" style={{ color: 'black', fontWeight: 400 }} gutterBottom>
                      <span style={{ color: 'rgb(28, 140, 177)', fontFamily: "'Montserrat', sans-serif", fontSize: 90, fontWeight: 700 }}>1+ </span>
                      <br />Location(s)
                    </Typography> */}

                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <div className={classes.paper}>
                  <div>
                    <Typography variant="h5" style={{ color: 'black', fontWeight: 400 }} gutterBottom>
                      <span style={{ color: '#ec2786', fontFamily: "'Montserrat', sans-serif", fontSize: 90, fontWeight: 700 }}>1+ </span>
                      <br />Hyderabad Location
                    </Typography>

                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <div className={classes.paper}>
                  <div>
                    {/* <Typography variant="h5" style={{ color: 'black', fontWeight: 400 }} gutterBottom>
                      <span style={{ color: 'rgb(28, 140, 177)', fontFamily: "'Montserrat', sans-serif", fontSize: 90, fontWeight: 700 }}>5+ </span>
                      <br />Projects
                    </Typography> */}
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Grid container direction="row" justify="center">
            <Grid spacing={40} alignItems="center" justify="center" container className={classes.grid4}>
              <Grid item xs={12} md={4}>
                <div className={classes.paper}>
                  <div>
                    {/* <Typography variant="h5" style={{ color: 'black', fontWeight: 400 }} gutterBottom>
                      <span style={{ color: 'rgb(28, 140, 177)', fontFamily: "'Montserrat', sans-serif", fontSize: 90, fontWeight: 700 }}>1+ </span>
                      <br />Location(s)
                    </Typography> */}

                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <div className={classes.paper}>
                  <div>
                    <Typography variant="h5" style={{ color: 'black', fontWeight: 400 }} gutterBottom>
                      <span style={{ color: '#ec2786', fontFamily: "'Montserrat', sans-serif", fontSize: 90, fontWeight: 700 }}>10+ </span>
                      <br />Team Members
                    </Typography>

                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <div className={classes.paper}>
                  <div>
                    {/* <Typography variant="h5" style={{ color: 'black', fontWeight: 400 }} gutterBottom>
                      <span style={{ color: 'rgb(28, 140, 177)', fontFamily: "'Montserrat', sans-serif", fontSize: 90, fontWeight: 700 }}>5+ </span>
                      <br />Projects
                    </Typography> */}
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Grid container direction="row" justify="center">
            <Grid spacing={40} alignItems="center" justify="center" container className={classes.grid4}>
              <Grid item xs={12} md={4}>
                <div className={classes.paper}>
                  <div>
                    {/* <Typography variant="h5" style={{ color: 'black', fontWeight: 400 }} gutterBottom>
                      <span style={{ color: 'rgb(28, 140, 177)', fontFamily: "'Montserrat', sans-serif", fontSize: 90, fontWeight: 700 }}>1+ </span>
                      <br />Location(s)
                    </Typography> */}

                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <div className={classes.paper}>
                  <div>
                    <Typography variant="h5" style={{ color: 'black', fontWeight: 400 }} gutterBottom>
                      <span style={{ color: '#ec2786', fontFamily: "'Montserrat', sans-serif", fontSize: 90, fontWeight: 700 }}>5+ </span>
                      <br />Projects
                    </Typography>

                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <div className={classes.paper}>
                  <div>
                    {/* <Typography variant="h5" style={{ color: 'black', fontWeight: 400 }} gutterBottom>
                      <span style={{ color: 'rgb(28, 140, 177)', fontFamily: "'Montserrat', sans-serif", fontSize: 90, fontWeight: 700 }}>5+ </span>
                      <br />Projects
                    </Typography> */}
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>


          <Grid container direction="row" justify="center">
            <Grid alignItems="center" justify="center" container className={classes.grid1}>
              <Grid item xs={12} md={2} lg={2} style={{ width: 280, height: 360 }} className='frame'>
                {/* <img src={avatar} alt="Mona Lisa" style={{
                  width: '160px',
                  height: '270px',
                  position: 'relative',
                  right: '69px',
                  bottom: '70px'
                }} /> */}
              </Grid>
              <Grid item xs={12} md={1} lg={1}>

              </Grid>
              <Grid item xs={12} md={2} lg={2} style={{ width: 300, height: 320, position: 'relative', top: 20, right: 60 }} className='frame'>
                {/* <img src={avatar} alt="Mona Lisa" style={{
                  width: '160px',
                  height: '230px',
                  position: 'relative',
                  right: '69px',
                  bottom: '70px'
                }} /> */}
              </Grid>
              <Grid item xs={12} md={7} lg={7}>
                {/* <Typography variant="h1" gutterBottom style={{ textAlign: 'left', color: '#ec2786', fontFamily: "'Montserrat', sans-serif", marginBottom: 30, fontSize: 60, fontWeight: 700 }}>
                  Team On Wall
                </Typography> */}
              </Grid>
            </Grid>
          </Grid>


          <Grid container direction="row" justify="center">
            <Grid alignItems="center" justify="center" container className={classes.grid1}>
              <Grid item xs={12} md={2} lg={2}>
              </Grid>
              <Grid item xs={12} md={2} lg={2} style={{ width: 300, height: 320, position: 'relative', top: 20 }} className='frame'>
                {/* <img src={avatar} alt="Mona Lisa" style={{
                  width: '160px',
                  height: '230px',
                  position: 'relative',
                  right: '69px',
                  bottom: '70px'
                }} /> */}
              </Grid>
              <Grid item xs={12} md={1} lg={1}>
              </Grid>
              <Grid item xs={12} md={4} lg={2} style={{ width: 320, height: 360, position: 'relative', top: 40, right: 60 }} className='frame'>
                {/* <img src={avatar} alt="Mona Lisa" style={{
                  width: '160px',
                  height: '280px',
                  position: 'relative',
                  right: '69px',
                  bottom: '70px'
                }} /> */}
              </Grid>
              <Grid item xs={12} md={1} lg={1}>
              </Grid>
              <Grid item xs={12} md={2} lg={2} style={{ width: 320, height: 330, position: 'relative', top: 54, right: 120 }} className='frame'>
                {/* <img src={avatar} alt="Mona Lisa" style={{
                  width: '160px',
                  height: '240px',
                  position: 'relative',
                  right: '69px',
                  bottom: '70px'
                }} /> */}
              </Grid>
              <Grid item xs={12} md={1} lg={1}>

              </Grid>
              <Grid item xs={12} md={2} lg={2}>

              </Grid>
            </Grid>
          </Grid>


          <Grid container direction="row" justify="center" style={{ marginTop: 60 }}>
            <Grid alignItems="center" justify="center" container className={classes.grid3}>


              <Grid item xs={12} md={2} lg={2}>

              </Grid>
              <Grid item xs={12} md={2} lg={2}>

              </Grid>
              <Grid item xs={12} md={2} lg={2}>
              </Grid>
              <Grid item xs={12} md={2} lg={1}>

              </Grid>
              <Grid item xs={12} md={2} lg={2} style={{ width: 280, height: 360, position: 'relative', top: 20, left: 54 }} className='frame'>
                {/* <img src={avatar} alt="Mona Lisa" style={{
                  width: '190px',
                  height: '270px',
                  position: 'relative',
                  right: '69px',
                  bottom: '70px'
                }} /> */}
              </Grid>
              <Grid item xs={12} md={1} lg={1}>

              </Grid>
              <Grid item xs={12} md={2} lg={2} style={{ width: 300, height: 320, position: 'relative', right: 20 }} className='frame'>
                {/* <img src={avatar} alt="Mona Lisa" style={{
                  width: '190px',
                  height: '230px',
                  position: 'relative',
                  right: '69px',
                  bottom: '70px'
                }} /> */}
              </Grid>

              <Grid item xs={12} md={1} lg={1}>

              </Grid>

            </Grid>
          </Grid>

        </div>
        <div style={{ marginTop: 180 }}></div>

        <HomePageFooterComponent />
      </React.Fragment >
    );
  }
}

const styles: any = (theme: any) => ({
  sample: {
    [theme.breakpoints.down('md')]: {
      marginBottom: 72,
    }
  },
  paper: {
    padding: theme.spacing.unit * 3,
    textAlign: 'center',
    color: theme.palette.text.secondary
  },
  box: {
    //marginBottom: 40,
    height: 200,
    textAlign: 'center'
  },
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 5,
    paddingBottom: theme.spacing.unit * 0,
    flexGrow: 1,
    marginTop: 30

  },
  grid: {
    width: '75%',
    marginTop: 40,
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 20px)'
    }
  },
  grid4: {
    width: '85%',
    //marginTop: 40,
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 20px)'
    },
    marginBottom: 40
  },
  avatar: {
    width: "280px",
    height: "360px"
  },
  grid1: {
    width: '90%',
    marginTop: 40,
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  grid3: {
    width: '100%',
    marginTop: 40,
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 20px)'
    }
  },
  Att: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 5,
    paddingBottom: theme.spacing.unit * 0,
  }
});

export default withStyles(styles)(TeamComponent);
