import { Button } from '@material-ui/core';
import React from 'react';
import { Route } from 'react-router';
import { ThemeConsumer } from '../../../themewrapper';

const Topic = ({ match }: any) => (
  <div>
    <h3>{match.params.topicId}</h3>
  </div>
)

export default class TopicsComponent extends React.Component<any, any> {

  // static contextTypes = {
  //   changeTheme: PropTypes.func
  // };

  constructor(props: any) {
    super(props);
    console.log(this.props);
    this.getTopics = this.getTopics.bind(this);

    this.state = {
      topicName: this.props.topicName,
      currentDateTime: this.props.currentDateTime
    };
  }

  componentDidMount() {

  }

  getTopics = async (changeTheme: Function) => {
    try {
      //changeTheme(defaultTheme);
      console.log(this.context);
      await this.props.getTopics();
      this.setState({
        topicName: this.props.topicName,
        currentDateTime: this.props.currentDateTime
      });
    }
    catch (ex) {
      console.log(ex);
    }
  }

  render() {
    return (
      <ThemeConsumer>
        {({ changeTheme }) => {
          return (
            <div>
              <h2>Topics</h2>
              <div>
                <Button onClick={() => { this.getTopics(changeTheme) }} color='primary' variant="outlined">
                  Get Topics
          </Button>
              </div>

              <div>
                {
                  this.state.topicName == null ? null :
                    (
                      <div>
                        {this.state.topicName}
                        {this.state.currentDateTime}
                      </div>
                    )
                }
              </div>

              <Route path={`${this.props.match.url}/:topicId`} component={Topic} />
              {/* <Route exact path={match.url} render={() => (
      <h3>Please select a topic.</h3>
    )} /> */}
            </div>
          )
        }}
      </ThemeConsumer>
    );
  }
}