import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import { Link, Route } from 'react-router-dom';
import { ActionType } from 'typesafe-actions';
import { Button } from '@material-ui/core';
import * as TopicActions from "../../redux/topics/actions";
import * as CommonActions from "../../redux/common/actions";
import TopicsComponent from '../../components/viewcomponents/topics/topics-component';
import SocialLoginFormComponent from '../../components/common/login/sociallogin-form-component';
import PropTypes from 'prop-types'

class TopicsContainer extends React.Component<any, any> {

  constructor(props: any) {
    super(props);
    console.log(this.props);
  }

  componentDidMount() {

  }

  getTopics = async (event: any) => {
    console.log("Topics button clicked");
    this.props.dispatch(TopicActions.getToipcsFromAPI()).then((data: any) => {
      console.log(data);
      this.context.changeTheme();
    });
  }


  render() {
    return (
      <div>
        <TopicsComponent
          {...this.props}
          getTopics={this.getTopics}
          topicName={this.props.topic.topicName}
          currentDateTime={this.props.topic.currentDateTime}
        />

      </div>
    )
  }
}


const mapStateToProps = (state: any) => {
  return {
    ...state
  }
};

export default connect(mapStateToProps)(TopicsContainer);