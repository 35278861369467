import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import BaseDialog from '../../common/BaseDialog';
import SwipeableViews from 'react-swipeable-views';
import MobileStepper from '@material-ui/core/MobileStepper';
import { autoPlay } from '../../common/common-JSFiles/thirdpart-jsfiles';

// const logo = require('../../images/logo.svg');

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const tutorialSteps = [
  {
    label: 'A first label',
    description: "We build Web, Mobile and Desktop App's",
    imgPath:
      'https://images.unsplash.com/photo-1506729623306-b5a934d88b53?auto=format&fit=crop&w=2100&h=250&q=60',
  },
  {
    label: 'A second label',
    description: 'We build end to end Product.',
    imgPath:
      'https://images.unsplash.com/photo-1535598745644-bc7913bb1a2a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2110&q=80',
  },
  {
    label: 'Other label',
    description: 'We design experiences which makes peoples lives simple.',
    imgPath:
      'https://images.unsplash.com/photo-1517180102446-f3ece451e9d8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2100&q=80',
  },
];


class SwipeDialog extends Component<any, any> {
  constructor(props: any) {
    super(props);
  }
  state = {
    activeStep: 0
  }

  handleNext = () => {
    this.setState((prevState: any) => ({
      activeStep: prevState.activeStep + 1,
    }));
  };

  handleBack = () => {
    this.setState((prevState: any) => ({
      activeStep: prevState.activeStep - 1,
    }));
  };

  handleStepChange = (activeStep: any) => {
    this.setState({ activeStep });
  };

  render() {
    const { classes } = this.props;
    const maxSteps = tutorialSteps.length;
    const { activeStep } = this.state;
    return (

      <div className={classes.container}>
        <div className={classes.gutterBottom}>
          {/* <img width={100} src={logo} alt="" /> */}
        </div>
        <div>
          <AutoPlaySwipeableViews
            axis='x'
            index={activeStep}
            onChangeIndex={this.handleStepChange}
            enableMouseEvents
            interval={8000}

          >
            {tutorialSteps.map((step, index) => (
              <div key={step.label}>
                {Math.abs(activeStep - index) <= 2 ? (
                  <img className={classes.img} src={step.imgPath} alt={step.label} style={{ width: '100%', height: 560 }} />
                ) : null}
              </div>
            ))}
          </AutoPlaySwipeableViews>
          <MobileStepper
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            className={classes.mobileStepper}
            nextButton={
              <Button size="small" onClick={this.handleNext} disabled={activeStep === maxSteps - 1}>
                Next
                </Button>
            }
            backButton={
              <Button size="small" onClick={this.handleBack} disabled={activeStep === 0}>
                Back
                </Button>
            }
          />
        </div>
        <div className={classes.stepsContainer}>
          <Typography style={{ fontFamily: 'EB Garamond', fontStyle: 'italic', fontSize: 40 }} color="primary" gutterBottom>
            " {tutorialSteps[activeStep].description} "
          </Typography>
          {/* <Typography variant="body2" gutterBottom>
            {tutorialSteps[activeStep].description}
          </Typography> */}
        </div>

      </div>

    )
  }
}

const styles: any = (theme: any) => ({
  container: {
    //maxWidth: 600,
    flexGrow: 1,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  stepsContainer: {
    //marginLeft: 72,
    textAlign: 'center',
    marginTop: 20,
    height: 65,
    marginBottom: 20
  },
  bottomMargin: {
    marginBottom: theme.spacing.unit * 2
  },
  mobileStepper: {
    display: 'flex',
    padding: '8px',
    backgroundColor: '#fafafa',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-evenly'
  }
});


export default withStyles(styles)(SwipeDialog);
