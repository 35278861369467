import React from 'react';
import { connect } from 'react-redux';
import Leaveapplication from '../../components/viewcomponents/leave/leave';

class Leavecontainer extends React.Component<any, any>{
    constructor(props: any) {
        super(props)
        console.log(this.props)
    }
    componentDidMount() {

    }
    render() {
        return (
            <React.Fragment>
                <Leaveapplication />
            </React.Fragment>
        )
    }
};
const mapstatetoprops = (state: any) => {
    return {
        ...state
    }
}
export default connect(mapstatetoprops)(Leavecontainer);