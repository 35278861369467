import React, { Component } from 'react';
import BigCalendar from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
moment.locale('en-GB');
BigCalendar.momentLocalizer(moment);
class Calender extends Component {
 
 componentDidMount() { 
   //Fetch events from database here
 }

render() {
    
    const myEventsList:any = []
    const localizer = BigCalendar.momentLocalizer(moment)
    return (
      <div className="App">
        <header className="App-header">
          <h1 className="App-title">Calendar</h1>
        </header>
        <div style={{ height: 700 }}>
          <BigCalendar
              localizer={localizer}
            events={myEventsList}
            step={30}
            defaultView='week'
            views={['month','week','day']}
            defaultDate={new Date()}
          />
        </div>
      </div>
    );
  }
}
export default Calender;
