import { Avatar, Card, CardHeader, CssBaseline, Divider, withStyles } from '@material-ui/core';
import Brush from '@material-ui/icons/Brush';
import React from 'react';
import { blueTheme, pinkTheme, purpleTheme, redTheme } from '../../../../store';
import { ThemeConsumer } from '../../../../themewrapper';

class UserSettingsTheme extends React.Component<any, any> {

  constructor(props: any) {
    super(props);

    this.changeBlueTheme = this.changeBlueTheme.bind(this);
    this.changePinkTheme = this.changePinkTheme.bind(this);
    this.changeRedTheme = this.changeRedTheme.bind(this);
    this.changePurpleTheme = this.changePurpleTheme.bind(this);
  }

  componentDidMount() {

  }

  changeBlueTheme = async (changeTheme: Function) => {
    try {
      await this.props.getThemeProperties(changeTheme, blueTheme);
     // changeTheme(blueTheme);
    }
    catch (ex) {
      console.log(ex);
    }
  }

  changePinkTheme = async (changeTheme: Function) => {
    try {
      await this.props.getThemeProperties(changeTheme, pinkTheme);
      //changeTheme(pinkTheme);
    }
    catch (ex) {
      console.log(ex);
    }
  }

  changeRedTheme = async (changeTheme: Function) => {
    try {
      await this.props.getThemeProperties(changeTheme, redTheme);
      //changeTheme(redTheme);
    }
    catch (ex) {
      console.log(ex);
    }
  }

  changePurpleTheme = async (changeTheme: Function) => {
    try {
      await this.props.getThemeProperties(changeTheme, purpleTheme);
      //changeTheme(purpleTheme);
    }
    catch (ex) {
      console.log(ex);
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <ThemeConsumer>
        {({ changeTheme }) => {
          return (
            <React.Fragment>
              <CssBaseline />
              {/* <Topbar /> */}
              <div className={classes.root}>
                <Card className={classes.card} style={{ textAlign: 'left', minHeight: 'auto' }}>
                  <CardHeader
                    avatar={
                      <Avatar aria-label="Recipe" className={classes.avatar}>
                        <Brush />
                      </Avatar>
                    }
                    title="Change Theme"
                    subheader="Changes theme of the app"
                    classes={{
                      title: classes.cardHeader,
                    }}
                  />

                  <Divider />
                  <Card className={classes.card} style={{ textAlign: 'left', height: 'auto' }}>
                    <div onClick={() => { this.changeBlueTheme(changeTheme) }} style={{ height: 100, display: 'flex', flexDirection: 'row', alignItems: "center", justifyContent: "flex-start", marginLeft: 60 }}>
                      <span style={{ marginRight: 40, width: 120, fontFamily: 'Nunito' }}>
                        Blue Theme
                      </span>
                      <div style={{ height: 60, width: 60, backgroundColor: 'blue', borderRadius: 30 }}>

                      </div>
                    </div>

                    <div onClick={() => { this.changePinkTheme(changeTheme) }} style={{ height: 100, display: 'flex', flexDirection: 'row', alignItems: "center", justifyContent: "flex-start", marginLeft: 60 }}>
                      <div style={{ marginRight: 40, width: 120, fontFamily: 'Nunito' }}>
                        Pink Theme
                      </div>
                      <div style={{ height: 60, width: 60, backgroundColor: 'pink', borderRadius: 30 }}>

                      </div>
                    </div>

                    <div onClick={() => { this.changeRedTheme(changeTheme) }} style={{ height: 100, display: 'flex', flexDirection: 'row', alignItems: "center", justifyContent: "flex-start", marginLeft: 60 }}>
                      <div style={{ marginRight: 40, width: 120, fontFamily: 'Nunito' }}>
                        Red Theme
                      </div>
                      <div style={{ height: 60, width: 60, backgroundColor: 'red', borderRadius: 30 }}>

                      </div>
                    </div>

                    <div onClick={() => { this.changePurpleTheme(changeTheme) }} style={{ height: 100, display: 'flex', flexDirection: 'row', alignItems: "center", justifyContent: "flex-start", marginLeft: 60 }}>
                      <div style={{ marginRight: 40, width: 120, fontFamily: 'Nunito' }}>
                        Purple Theme
                      </div>
                      <div style={{ height: 60, width: 60, backgroundColor: 'purple', borderRadius: 30 }}>

                      </div>
                    </div>
                  </Card>

                </Card>
              </div>
            </React.Fragment>
          )
        }}
      </ThemeConsumer>
    )
  }
}

const styles: any = (theme: any) => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#f9fcff'
    //overflow: 'hidden',
    //background: `url(${backgroundShape}) no-repeat`,
    //backgroundSize: 'cover',
    //backgroundPosition: '0 400px',
    //paddingBottom: 200
  },
  grid: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 20px)'
    }
  },
  cardHeader: {
    color: theme.palette.text.primary,
    fontSize: 18
  },

  boxcontent: {
    width: 420,
    height: 720,
    backgroundColor: '#ffffff',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'calc(100% - 80%)',
      width: '60%'
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 'calc(100% - 94%)',
      width: '90%'
    }
  }
});


export default withStyles(styles)(UserSettingsTheme);