import { Paper } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
// import CardActions from '@material-ui/core/CardActions';
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import MailIcon from '@material-ui/icons/Mail';
import React from "react";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
 import { Row, Col } from "react-flexbox-grid";
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
// import TextField from "@material-ui/core/TextField";
import Calendar from "react-calendar";
import withWidth from "material-ui/utils/withWidth";
// import {BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend,} from 'recharts';

class EndSession extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      Rows: []
    }
  }
 

  onChange = (date: any) => this.setState({ date });
  render() {
    const { classes } = this.props;
    

   
    return (
      <React.Fragment>
        {/* <Grid container className={classes.colorcontainer}>
          <Grid item xs={12}> */}
            {/* <div>
    
            <AppBar style={{position:"static",backgroundColor:"#ffffff"}}>
        <Toolbar>
              <br />
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: "29px",
                  fontWeight: "bold",
                  marginLeft: "10px",
                  color: "#707090",
                  paddingBottom: "8px",
                paddingLeft: "10px"
                }}
              >
                Work report
                
              </Typography>
              </Toolbar>
              </AppBar>
            </div> */}
            
          {/* </Grid>
        </Grid>  */}
        {/* <Grid container className={classes.colorcontainer}>
          <Grid item xs={12}> */}
          <div style={{position:"relative",bottom: 5,}}>
          <br />
          <Typography
            style={{
              fontSize: "22px",
              fontWeight: "bold",
              color: "black"
            
            }}
          >
            Attendance
            <hr />
          </Typography>
        
            {/* <div style={{backgroundColor:"#efefed"}}>
          
              <Tabs
                value={this.state.value}
                // indicatorColor="primary"
                // textColor="primary"


              >
                
             <Tab style={{
                  // fontFamily: "Roboto",
                  fontSize: "20px",
                  fontWeight: "bold",
                  marginLeft: "55px",
                  Color: "#efefed",
                  borderBottom:'2px solid #1ea7a6'
                }} label="Attendance" > </Tab>
                
              </Tabs>
              

              {/* <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: "20px",
                  fontWeight: "bold",
                  marginLeft: "26px",
                  color: "Grey"
                }}
              >
                Attendence
                <hr />
              </Typography> */}
{/*               
            </div> */} 
            </div>
          {/* </Grid>
        </Grid> */}
        <Grid container className={classes.rootcontainer}>
          <Grid
            alignItems="flex-start"
            justify="flex-start"
            container
            xs={12}
            spacing={24}
            direction="row"
            className={classes.cardsContainer1}
          >
            <Grid item xs={12} sm={12} md={8} className={classes.card1}>
              <Card className={classes.card1}>
                <CardContent>
                  {/* <Paper style={{ height: "235px" }} className={classes.Paper1}> */}
                  {/* <BarChart
        width={500}
        height={300}
        margin={{
          top: 5, right: 30, left: 20, bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="pv" fill="#8884d8" />
        <Bar dataKey="uv" fill="#82ca9d" />
      </BarChart> */}
                  {/* </Paper>
                  <div></div> */}
                  
     
                  {/* <hr style={{ color: "paleblue" }}></hr> */}

                  {/* <div style={{}}>
                    <Typography
                      style={{ fontSize: "25px", fontWeight: "bold" }}
                    >
                     Working Hours By Day In This Month
                    </Typography>
                    <hr />
                  </div>
                  <div style={{}}>
                    <Typography
                      style={{ fontSize: "15px", fontWeight: "bold" }}
                    >
                      Working Hours By Day In This Month
                    </Typography>
                    12<hr />
                  </div>
                  <div style={{}}>
                    <Typography
                      style={{ fontSize: "20px", }}
                    >
                      Working Hours By Day In This Month Working Hours By Day In This Month Working Hours By Day In This Month
                    </Typography>
                  </div>
                  8<hr />
                  <div style={{}}>
                    <Typography
                      style={{ fontSize: "20px" }}
                    >
                      Working Hours By Day In This Month Working Hours By Day In This Month Working Hours By Day In This Month
                    </Typography>
                  </div>
                  4<hr />
                  <div style={{}}>
                    <Typography
                      style={{ fontSize: "20px" }}
                    >
                      Working Hours By Day In This Month
                    </Typography>
                  </div>
                  0<hr /> */}
                  {/* <Paper className={classes.Paper2}>
                    <div style={{}}>
                      <Typography
                        style={{ fontSize: "15px", fontWeight: "bold", padding: "8px 7px 0px" }}
                      >
                        Attendence Information
                    </Typography>
                    </div>
                    <hr />
                    <div style={{}}>
                      <Typography
                        style={{padding: "8px 7px 0px" }}
                      >
                        <p style={{flexDirection:"row",padding:"8px 2px 2px"}}>First Out Time :  <span/> 10:00:AM <span/> <span/>IP Address :<span/>5c:6h:uj:7u
                           {/* {<TextFieldS
                          style={{ fontSize: "20px", padding: "8px 7px 0px", }}>

                        </TextField> */} 
                     {/* </p>  </Typography>


                    </div>
                    <div style={{}}>
                      <Typography
                        style={{ padding: "8px 7px 0px" }}
                      >
                       <p style={{flexDirection:"row",padding:"8px 2px 2px"}}>Last Out Time : <span><span>07:00:PM</span></span>  <span/>Location :<span/>Manikonda</p> 
                           {/* <TextField
                          style={{ fontSize: "20px", padding: "8px 7px 0px", borderStyle: "none" }}>

                        </TextField> */}
                      {/* </Typography>
                    </div>
                    <div style={{}}>
                      <Typography
                        style={{padding: "8px 7px 0px" }}
                      >
                        <p style={{flexDirection:"row",padding:"8px 2px 2px"}}> Working Hours : <span><span> 9Hours </span></span></p> */}
                          {/* <Typography
                            style={{ fontSize: "20px", padding: "8px 7px 0px" }}
                          >9Hours</Typography> */}
                          {/* <TextField
                          style={{ fontSize: "20px", padding: "8px 7px 0px", }}>
                        </TextField> */}
                      {/* </Typography>

                    </div>
                  </Paper> */}
                  {/* <hr /> */}
                  {/* <Paper style={{ height: "179px" }} className={classes.Paper3}>
                    <div style={{}}>
                      <Typography
                        style={{ fontSize: "13px", fontWeight: "bold", padding: "8px 7px 0px",color:"#4072ee" }}
                      >
                        Today Works Inputs
                    </Typography>
                    </div>
                    <hr />
                    <div style={{}}>
                      <Typography
                        style={{ fontSize: "13px", padding: "8px 7px 0px" }}
                      >
                        Hello,
                    </Typography>
                    </div>
                    <div style={{}}>
                      <Typography
                        style={{ fontSize: "13px", padding: "8px 7px 0px" }}
                      >
                        Thank you for such a great Opportunity. I learn a lot from you.
                    </Typography>
                    </div>
                    <div style={{}}>
                      <Typography
                        style={{ fontSize: "13px", padding: "8px 7px 0px" }}
                      >
                        Let's Keep In Touch.
                    </Typography>
                    </div>
                    <div style={{}}>
                      <Typography
                        style={{ fontSize: "13px", padding: "8px 7px 0px" }}
                      >
                        Saina.Gowtham
                    </Typography>
                    </div>
                  </Paper> */} 

              
                {/* <Grid container direction="column" className={classes.cardsMaincontainer}>
                <Grid item xs={12} md={8} className={classes.cardsMaincontainer}> */}
                  <div className={classes.Maincontainer} style={{height:"315px"}}>
                   <Typography style={{fontSize: "20px", fontWeight: "bold", padding: "8px 7px 0px",color:"#555577"}}>Working hours by Day in this Month </Typography>
                  <hr/>
                  </div>
                  <hr></hr>
                  <div className={classes.Maincontainer2}style={{height:"250px"}}>
                  <div>
                  <Typography style={{fontSize:"20px", fontWeight: "bold", padding: "8px 7px 0px",color:"#555577"}}>Attendance information</Typography>
                  </div>
                  <div>
                    <Typography style={{fontSize:"13px",fontWeight: "bold",padding: "8px 7px 0px",color:"grey"}}>First In Time : <span style={{fontSize:"13px",fontWeight: "bold",paddingLeft:"45px",color:"grey"}}>10:00:00 AM</span>
                    <span style={{fontSize:"13px",fontWeight: "bold",paddingLeft:"45px",color:"grey"}}>IP Address :</span> <span style={{fontSize:"13px",fontWeight: "bold",paddingLeft:"22px",color:"grey"}}>5c:6h:uj:7u</span>
                    </Typography>
                 </div>
                 <div>
                    <Typography style={{fontSize:"13px",padding: "8px 7px 0px",fontWeight: "bold",color:"grey"}}>Last Out Time : <span style={{fontSize:"13px",fontWeight: "bold",paddingLeft:"35px",color:"grey"}}>07:00:00 PM</span>
                    <span style={{fontSize:"13px",fontWeight: "bold",paddingLeft:"45px",color:"grey"}}>Location :</span> <span style={{fontSize:"13px",fontWeight: "bold",paddingLeft:"38px",color:"grey"}}>Manikonda</span>
                    </Typography>
                 </div>
                 <div>
                    <Typography style={{fontSize:"13px",fontWeight: "bold",padding: "8px 7px 0px",color:"grey"}}>Work Hours : <span style={{fontSize:"13px",fontWeight: "bold",paddingLeft:"48px",color:"grey"}}>9 Hours</span>
                    <span style={{fontSize:"13px",fontWeight: "bold",paddingLeft:"72px",color:"grey"}}>Excess Hours :</span> <span style={{fontSize:"13px",fontWeight: "bold",paddingLeft:"9px",color:"grey"}}>2 Hours</span>
                    </Typography>
                 </div>
                 </div>
                 <hr/>
                 <div>
                 <Typography style={{fontSize:"15px", fontWeight: "bold", padding: "8px 7px 0px",color:"#4072ee"}}>Today work inputs</Typography>
                 </div>
                 <div className={classes.container}>
                    <TextField
                      id="filled-multiline-flexible" label="Work Input" style={{ margin: 4}}multiline 
                      fullWidth margin="normal"  variant="filled"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    </div>
                 {/* <div>
                 <Typography style={{fontSize:"13px", fontWeight: "bold", padding: "8px 7px 0px",color:"#707090"}}>Hello</Typography>
                 </div>
                 <div>
                 <Typography style={{fontSize:"13px", fontWeight: "bold", padding: "8px 7px 0px",color:"#707090"}}>Thank you for such a great Opportunity. I learn a lot from you.</Typography>
                 </div>
                 <div>
                 <Typography style={{fontSize:"13px", fontWeight: "bold", padding: "8px 7px 0px",color:"#707090"}}>Let’s keep in touch.</Typography>
                 </div>
                 <div>
                 <Typography style={{fontSize:"13px", fontWeight: "bold", padding: "8px 7px 0px",color:"#707090"}}>Saina Gowtham</Typography>
                 </div> */}
                  {/* </Grid>
                  </Grid> */}

                  <Button
                    style={{
                      // width: "80px",
                      // height: "30px",
                      marginTop: "20px",
                      color:"white",
                      backgroundColor:"#ff5b6f"
                    }}
                    variant="contained"
                    // size="large"
                    color="primary"
                    className={classes.margin}
                  >
                    End Session
                        </Button> 

                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Grid
              container
              spacing={24}
              alignItems="stretch"
              justify="flex-start"
              className={classes.calendarContainer}
            >
              <Grid item sm={6} md={12} xs={12}>
                <Card className={classes.card2}>
                  <CardContent>
                    <Typography
                      className={classes.headerStyle}
                      style={{ marginBottom: 10 }}
                    >
                      This Month Report
                    </Typography>
                    {/* <Typography
                      style={{
                        fontSize: "16px",
                        marginTop: "20px",
                        fontWeight: "bold"
                      }}
                    >
                      Calendar
                    </Typography> */}
                    <div>
                      <Calendar
                        onChange={this.onChange}
                        value={this.state.date}
                      />
                    </div>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item sm={6} md={12} xs={12}>
                <Card className={classes.card3}>
                  <CardContent>
                    <div>
                      <Typography
                        className={classes.headerStyle}
                        style={{
                          marginBottom: "10px"
                        }}
                      >
                        Legend
                      </Typography>
                      <Grid item style={{ marginTop: "14px" }}>

                        <Row style={{ marginLeft: 12, marginBottom: 20 }} className={classes.subLeavefieldscontainer2}>
                          <Col xs={3} md={3} lg={3}>
                            <div
                              style={{
                                backgroundColor: "grey",
                                width: "40px",
                                height: "40px",
                                borderRadius: 4
                              }}
                            >
                            </div>
                          </Col>
                          <Col xs={5} md={5} lg={9}>
                            <Typography>Present</Typography>
                          </Col>
                        </Row>

                        <Row style={{ marginLeft: 12, marginBottom: 20 }} className={classes.subLeavefieldscontainer2}>
                          <Col xs={3} md={3} lg={3}>
                            <div
                              style={{
                                backgroundColor: "cyan",
                                width: "40px",
                                height: "40px",
                                borderRadius: 4
                              }}
                            >
                            </div>
                          </Col>
                          <Col xs={5} md={5} lg={9}>
                            <Typography>Comp Off</Typography>
                          </Col>
                        </Row>

                        <Row style={{ marginLeft: 12, marginBottom: 20 }} className={classes.subLeavefieldscontainer2}>
                          <Col xs={3} md={3} lg={3}>
                            <div
                              style={{
                                backgroundColor: "orange",
                                width: "40px",
                                height: "40px",
                                borderRadius: 4
                              }}
                            >
                            </div>
                          </Col>
                          <Col xs={5} md={5} lg={9}>
                            <Typography>Absent</Typography>
                          </Col>
                        </Row>

                        <Row style={{ marginLeft: 12, marginBottom: 20 }} className={classes.subLeavefieldscontainer2}>
                          <Col xs={3} md={3} lg={3}>
                            <div
                              style={{
                                backgroundColor: "blue",
                                width: "40px",
                                height: "40px",
                                borderRadius: 4
                              }}
                            >
                            </div>
                          </Col>
                          <Col xs={5} md={5} lg={9}>
                            <Typography>Leave</Typography>
                          </Col>
                        </Row>
                       </Grid>
                       </div>
                       </CardContent>
                       </Card>
                      </Grid>
            </Grid>
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}
const style: any = (theme: any) => ({
  colorcontainer: {
  //   backgroundColor: "#ffffff"

  },
  rootcontainer: {
    // backgroundColor: "#efefed"
  },
  cardsContainer2: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-evenly",
      marginLeft: "10px",

    }
  },
  // Maincontainer:{
  //   [theme.breakpoints.down("sm")]: {
  //     display: "flex",
  //     flexDirection: "column",
  //     justifyContent: "space-evenly",
  //     marginLeft: "10px",

  //   }
  // },
   
  Maincontainer2:{
    [theme.breakpoints.down("sm,md")]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-evenly",
      marginLeft: "10px",

    }
  },
  // ccto: {

  //   [theme.breakpoints.only('xs')]: {
  //     display: "flex",
  //     // justifyContent: "space-around",
  //   }
  // },

  card1: {
    // marginLeft: "px",
    // marginBottom: "6px"
  },
  Paper1: {
    marginBottom: "6px"
  },
  Paper2: {
    marginBottom: "6px"
  },
  Paper3: {
    marginBottom: "6px"
  },
  card2: {
    height: "383px",
    marginBottom: "6px",
    // withWidth:"100px"
    // [theme.breakpoints.down("xs")]: {
    //   width: "355px"
    // }
  },
 
  calendarContainer: {
    direction: "column",
    [theme.breakpoints.only('lg')]: {
      direction: "row"
    }
  },

  card3: {
    height: "383px",
    marginBottom: "6px",
    // withWidth:"100px",
    [theme.breakpoints.down("sm")]: {}
  },
  Fieldsrootcontainer: {},
  Leavefield: {
    width: "200px"
    // [theme.breakpoints.down("xs")]: {
    //   width: "260px",

    //   justifyContent:"space-between"
    // }
  },
  subLeavefieldscontainer: {
    display: "flex",
    // justifyContent: "space-evenly",
    justifyContent: "space-around",
    // marginLeft:"25px",
    // justifyContent:"space-between",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexWrap: "wrap"

      // justifyContent:"flex-start",
    }
  },
  Leavefields: {
    [theme.breakpoints.down("xs")]: {
      //  justifyContent:"space-between"
    }
  },
  subLeavefieldscontainer2: {
    display: "flex",
    // justifyContent: "space-evenly",
    justifyContent: "center",
    alignItems: "center",
    // flexDirection: "row",
    // marginLeft:"25px",
    // justifyContent:"space-between",
    [theme.breakpoints.down("xs","lg")]: {
      display: "flex",
      flexWrap: "wrap"

      // justifyContent:"flex-start",
    },
    //marginLeft: 12
  },
  footerappbar: {
    // width: "700px",
    marginLeft: "25px",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0px"
    }
  },
  // Textfields: {
    //  [theme.breakpoints.down('xs')]:{
    //  },
    //  [theme.breakpoints.down('sm')]:{
    //   marginLeft:"80px"
    // }
  // },
  headerStyle: {
    color: theme.contentHeader. headerColor,
    fontSize: "22px",
    borderStyle:"none",
  },
  iconstyle: {
    display: "flex",
    justifyContent: "space-between"
  }
});
export default withStyles(style)(EndSession);
