import { Paper } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import React, { Component } from "react";
const avatar = require("../../../../www/assets/images/avatar.png");
class Employee extends Component<any, any> {
  constructor(props: any) {
    super(props);
    console.log(this.props);
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Paper>
        <div style={{ position: "relative", bottom: 20 }}>
          <br />
          <Typography
            style={{ fontSize: "22px", fontWeight: "bold", color: "black" }}
          >
            Profile
            <hr />
          </Typography>
        </div>
        {/* <Grid container direction="row" justify="center"> */}
        <Grid
          alignItems="flex-start"
          justify="flex-start"
          container
          spacing={40}
          direction="row">
          <Grid item xs={12} sm={12} md={8} lg={8} style={{}}>
            <div className={classes.main}>
              <Typography style={{fontSize: "22px", fontWeight: "bold", color: "black",marginLeft:"10px"}}>Employee profile</Typography>
              <button style={{backgroundColor:'#33ccff',border:'none',width:'15%',borderRadius:'5'}}>EDIT</button>
            </div>

            <Grid container direction="row" style={{ flexDirection: 'row',flexWrap:'nowrap'}}>
              
                <Grid item xs={12} lg={6} xl={6} className={classes.card} style={{borderTop: "4px solid #ffcccc",}}>
                <Card>
                  <img src={avatar} className={classes.avatar} />
                </Card>
              </Grid>
              <Grid item xs={12} lg={6} xl={6} className={classes.card} style={{borderTop: "4px solid #99e6ff",}}>
                <Card>
                  <CardContent>
                  <Typography>
                      <b>Current Position</b>
                    </Typography>
                    <Typography> Department </Typography>
                    <Typography> Location </Typography>
                    <Typography> Designation </Typography>
                    <Typography> Repoting To </Typography>
                  </CardContent>
                </Card>
              </Grid>   
            </Grid>
            <Grid container  direction="row" style={{flexDirection: 'row',flexWrap:'nowrap'}}>

              <Grid item xs={12} lg={6} xl={6} className={classes.card} style={{borderTop: "4px solid #ffe0b3",}}>
                <Card>
                  <CardContent>
                  <Typography>
                      <b>Personal Information</b>
                    </Typography>
                    <Typography> Birthday </Typography>
                    <Typography> Blood Group </Typography>
                    <Typography> Marital Status </Typography>
                    <Typography> Spouse Name </Typography>
                    <Typography> Nationality </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} lg={6} xl={6} className={classes.card} style={{borderTop: "4px solid  #9fdfbf",}}>
                <Card>
                  <CardContent>
                    <Typography><b>Joining Details</b></Typography>
                    <Typography> "Joined On" </Typography>
                    <Typography> "Conformation Date" </Typography>
                    <Typography> "Satus" </Typography>
                    <Typography> "Notice Period" </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={12} sm={12} md={4} lg={4}>
          <Grid item xs={12} style={{height:'290px'}}>
              
            </Grid>
            <Grid item xs={12}>
              <TextField id="standard-name" label="Hinttext" className={classes.textField} margin="normal"/>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      </React.Fragment>
    );
  }
}

const styles: any = (theme: any) => ({
  main: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%"
  },
  card: {
    marginTop: "10px",
    marginLeft: "10px",
    // borderTop: "4px solid #33ccff",
  },
  avatar: {
    width: "110px",
    height: "142px"
  },
  grid: {
    width: "80%",
    marginTop: 5,
    border: "2px solid"
  },
  grid1: {
    width: "70%",
    display: "flex"
  },
  textField: {
    marginLeft: "10px"
  }
});

export default withStyles(styles)(Employee);
