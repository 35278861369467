import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import HompageComponent from '../components/viewcomponents/home/homepage-component';
import HomeHeadingComponent from '../components/viewcomponents/home/homepage-heading-content';

class Home extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    console.log(this.props);
  }

  render() {
    return (
      <div style={{ height: 'calc(100vh)' }}>
        <HomeHeadingComponent {...this.props} />
      </div>
    )
  }
}


const mapStateToProps = (state: any) => {
  return {
    ...state
  }
};

export default connect(mapStateToProps)(Home);