import { CssBaseline } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import React, { Component } from "react";
import 'react-vertical-timeline-component/style.min.css';
import WorkOffIcon from '@material-ui/icons/WorkOff';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import HomePageFooterComponent from '../home/homepage-footer';
import HomePageMenu from '../home/homepage-header';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
const webIcon = require('../../../../www/assets/icons/web.png');

class ContactComponent extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {

    }
  }

  componentDidUpdate(prevProps: any, prevState: any) {

  }

  render() {

    const { classes } = this.props;
    return (
      <React.Fragment>
        <CssBaseline />
        <HomePageMenu {...this.props} />
        <div style={{ marginTop: 40 }}></div>
        <div className={classes.root}>
          <Grid container direction="row" justify="center">
            <Grid spacing={24} alignItems="center" justify="center" container className={classes.grid}>
              <Grid container item xs={12} >

                <Grid item xs={12} style={{ height: 460, marginTop: 90 }}>
                  <div style={{ textAlign: 'center' }} className={classes.sample}>
                    {/* <Typography variant="h3" gutterBottom className={classes.marginbottom72}>
                      Our Services | What we do
                  </Typography> */}

                    <Typography variant="h1" gutterBottom style={{ color: '#1534e3', fontFamily: "'Montserrat', sans-serif", marginBottom: 30, fontSize: 90, fontWeight: 700 }}>
                      Contact Us
                  </Typography>

                    <Typography style={{ fontFamily: 'Montserrat', fontSize: 20, fontWeight: 300, paddingTop: 40 }} gutterBottom>
                      We think as you think and develop content perfectly tailored to your bussiness.
                    <br />We follow Agile Process.
                  </Typography>

                  </div>
                </Grid>

              </Grid>
            </Grid>
          </Grid>
        </div>

        <HomePageFooterComponent />
      </React.Fragment >
    );
  }
}

const styles = (theme: any) => ({
  sample: {
    [theme.breakpoints.down('md')]: {
      marginBottom: 72,
    }
  },

  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 5,
    paddingBottom: theme.spacing.unit * 0,
    flexGrow: 1,
    marginTop: 30

  },
  grid: {
    width: '75%',
    marginTop: 40,
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 20px)'
    }
  },
  Att: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 5,
    paddingBottom: theme.spacing.unit * 0,
  }
});

export default withStyles(styles)(ContactComponent);
