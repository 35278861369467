import React,{Component} from "react";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
// import CardActions from '@material-ui/core/CardActions';
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Tab from "@material-ui/icons/Tab";

import Calendar from "react-calendar";
import { Row, Col } from "react-flexbox-grid";

const currencies = [
  {
    value: "USD",
    label: "$"
  },
  {
    value: "EUR",
    label: "€"
  },
  {
    value: "BTC",
    label: "฿"
  },
  {
    value: "JPY",
    label: "¥"
  }
];
class Leaveapplication extends React.Component<any, any> {
  state = {
    currency: "EUR",
    date: new Date()
  };

  onChange = (date: any) => this.setState({ date });
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <div style={{ position: "relative", bottom: 20 }}>
          <br />
          <Typography
            style={{
              fontSize: "22px",
              fontWeight: "bold",
              color: "black"
            }}
          >
            Leave
            <hr />
          </Typography>
        </div>

        {/* <Grid container direction="row" justify="center"> */}
        <Grid
          alignItems="flex-start"
          justify="flex-start"
          container
          spacing={8}
          direction="row"
          style={{ position: "relative", bottom: 20 }}
        >
          <Grid item xs={12} sm={12} md={8} lg={9} xl={8}>
            <Card>
              <CardContent>
                <div style={{ padding: 12 }}>
                  <Typography
                    className={classes.headerStyle}
                  >
                    Apply for leave
                  </Typography>
                  <hr />
                </div>
                <div className={classes.Fieldsrootcontainer}>
                  <div>
                    <Row style={{ marginLeft: 12 }} className={classes.subLeavefieldscontainer1}>
                      <Col xs={2} md={2} lg={2}>
                        <div className={classes.subLeavefields}>
                          <Typography>Leave Type : </Typography>
                        </div>
                      </Col>

                      <Col xs={6} md={6} lg={6}>
                        <div className={classes.subLeavefields}>
                          <TextField
                            id="outlined-select-currency"
                            select
                            label="Leave Type"
                            value={this.state.currency}
                            margin="normal"
                            variant="outlined"
                            className={classes.Leavefield}
                          >
                            {currencies.map(option => (
                              <MenuItem
                                key={option.value}
                                value={option.value}
                              >
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row style={{ marginLeft: 12 }} className={classes.subLeavefieldscontainer1}>

                      <Col xs={12} md={6} lg={6}>
                        <Row className={classes.subLeavefieldscontainer1}>
                          <Col xs={4} md={4} lg={4}>
                            <div className={classes.subLeavefields}>
                              <Typography>From Date : </Typography>
                            </div>
                          </Col>
                          <Col xs={8} md={8} lg={8}>
                            <div className={classes.subLeavefields}>
                              <TextField
                                required
                                className={classes.Leavefield}
                                id="outlined-dense"
                                label="From Date"
                                margin="normal"
                                variant="outlined"
                              />
                            </div>
                          </Col>
                        </Row>
                      </Col>

                      <Col xs={12} md={6} lg={6}>
                        <Row className={classes.subLeavefieldscontainer1}>
                          <Col xs={4} md={4} lg={4}>
                            <div className={classes.subLeavefields}>
                              <Typography>To Date : </Typography>
                            </div>
                          </Col>
                          <Col xs={8} md={8} lg={8}>
                            <div className={classes.subLeavefields}>
                              <TextField
                                required
                                className={classes.Leavefield}
                                id="outlined-dense"
                                label="To Date"
                                margin="normal"
                                variant="outlined"
                              />
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row style={{ marginLeft: 12 }} className={classes.subLeavefieldscontainer1}>

                      <Col xs={12} md={6} lg={6}>
                        <Row className={classes.subLeavefieldscontainer1}>
                          <Col xs={4} md={4} lg={4}>
                            <div className={classes.subLeavefields}>
                              <Typography>Days : </Typography>
                            </div>
                          </Col>
                          <Col xs={8} md={8} lg={8}>
                            <div className={classes.subLeavefields}>
                              <TextField
                                disabled
                                className={classes.Leavefield}
                                id="outlined-dense"
                                label="12"
                                margin="normal"
                                variant="outlined"
                                style={{ backgroundColor: '#dcdcdc' }}
                              />
                            </div>
                          </Col>
                        </Row>
                      </Col>

                      <Col xs={12} md={6} lg={6}>
                        <Row className={classes.subLeavefieldscontainer1}>
                          <Col xs={4} md={4} lg={4}>
                            <div className={classes.subLeavefields}>
                              <Typography>Balance : </Typography>
                            </div>
                          </Col>
                          <Col xs={8} md={8} lg={8}>
                            <div className={classes.subLeavefields}>
                              <TextField
                                disabled
                                className={classes.Leavefield}
                                id="outlined-dense"
                                label="6"
                                margin="normal"
                                variant="outlined"
                                style={{ backgroundColor: '#dcdcdc' }}
                              />
                            </div>
                          </Col>
                        </Row>
                      </Col>

                    </Row>
                  </div>
                  <div>
                    <Row style={{ marginLeft: 12 }} className={classes.subLeavefieldscontainer1}>
                      <Col xs={2} md={2} lg={2}>
                        <div className={classes.subLeavefields}>
                          <Typography>Applies To : </Typography>
                        </div>
                      </Col>

                      <Col xs={6} md={6} lg={6}>
                        <div className={classes.subLeavefields}>
                          <TextField
                            id="outlined-select-currency"
                            select
                            label="Leave Type"
                            value={this.state.currency}
                            margin="normal"
                            variant="outlined"
                            className={classes.Leavefield}
                          >
                            {currencies.map(option => (
                              <MenuItem
                                key={option.value}
                                value={option.value}
                              >
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div>

                    <Row style={{ marginLeft: 12 }} className={classes.subLeavefieldscontainer1}>
                      <Col xs={2} md={2} lg={2}>
                        <div className={classes.subLeavefields}>
                          <Typography>Reason: </Typography>
                        </div>
                      </Col>

                      <Col xs={6} md={6} lg={6}>
                        <div className={classes.subLeavefields}>
                          <TextField
                            multiline
                            margin="normal"
                            variant="outlined"
                            className={classes.Leavefield}
                            rowsMax="4"
                            label="Enter the reason for leave"
                          />  `

                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div style={{ marginTop: 20 }}>
                    <Row style={{ marginLeft: 12 }} className={classes.subLeavefieldscontainer2}>
                      <Col xs={4} md={4} lg={2}>
                        <Button
                          style={{ marginLeft: "50px" }}
                          variant="contained"
                          color="primary"
                        >
                          Apply
                          </Button>
                      </Col>

                      <Col xs={4} md={4}  lg={2}>
                        <div className={classes.subLeavefields}>
                          <Button
                            style={{ marginLeft: "20px" }}
                            variant="contained"
                            color="primary"
                          >
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={3} xl={4}>
            <Grid
              container
              spacing={24}
              alignItems="stretch"
              justify="flex-start"
              className={classes.calendarContainer}
            >
              <Grid item sm={6} md={12} xs={12}>
                <Card className={classes.card2}>
                  <CardContent>
                    <Typography
                      className={classes.headerStyle}
                      style={{ marginBottom: 10 }}
                    >
                      This Month Report
                    </Typography>
                    {/* <Typography
                      style={{
                        fontSize: "16px",
                        marginTop: "20px",
                        fontWeight: "bold"
                      }}
                    >
                      Calendar
                    </Typography> */}
                    <div>
                      <Calendar
                        onChange={this.onChange}
                        value={this.state.date}
                      />
                    </div>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item sm={6} md={12} xs={12}>
                <Card className={classes.card3}>
                  <CardContent>
                    <div>
                      <Typography
                        className={classes.headerStyle}
                        style={{
                          marginBottom: "10px"
                        }}
                      >
                        Legend
                      </Typography>
                      <Grid item style={{ marginTop: "14px" }}>

                        <Row style={{ marginLeft: 12, marginBottom: 20 }} className={classes.subLeavefieldscontainer2}>
                          <Col xs={3} md={3} lg={3}>
                            <div
                              style={{
                                backgroundColor: "grey",
                                width: "40px",
                                height: "40px",
                                borderRadius: 4
                              }}
                            >
                            </div>
                          </Col>
                          <Col xs={5} md={5} lg={9}>
                            <Typography>Present</Typography>
                          </Col>
                        </Row>

                        <Row style={{ marginLeft: 12, marginBottom: 20 }} className={classes.subLeavefieldscontainer2}>
                          <Col xs={3} md={3} lg={3}>
                            <div
                              style={{
                                backgroundColor: "cyan",
                                width: "40px",
                                height: "40px",
                                borderRadius: 4
                              }}
                            >
                            </div>
                          </Col>
                          <Col xs={5} md={5} lg={9}>
                            <Typography>Comp Off</Typography>
                          </Col>
                        </Row>

                        <Row style={{ marginLeft: 12, marginBottom: 20 }} className={classes.subLeavefieldscontainer2}>
                          <Col xs={3} md={3} lg={3}>
                            <div
                              style={{
                                backgroundColor: "orange",
                                width: "40px",
                                height: "40px",
                                borderRadius: 4
                              }}
                            >
                            </div>
                          </Col>
                          <Col xs={5} md={5} lg={9}>
                            <Typography>Absent</Typography>
                          </Col>
                        </Row>

                        <Row style={{ marginLeft: 12, marginBottom: 20 }} className={classes.subLeavefieldscontainer2}>
                          <Col xs={3} md={3} lg={3}>
                            <div
                              style={{
                                backgroundColor: "blue",
                                width: "40px",
                                height: "40px",
                                borderRadius: 4
                              }}
                            >
                            </div>
                          </Col>
                          <Col xs={5} md={5} lg={9}>
                            <Typography>Leave</Typography>
                          </Col>
                        </Row>

                      </Grid>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}
const style: any = (theme: any) => ({
  colorcontainer: {
    // backgroundColor: "rgb(0, 69, 112)"
  },
  rootcontainer: {
    //backgroundColor: "rgb(1, 145, 116)"
  },
  cardsContainer2: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-evenly",
      marginLeft: "10px",

    }
  },
  ccto: {

    [theme.breakpoints.only('xs')]: {
      display: "flex",
      // justifyContent: "space-around",
    }
  },

  card1: {
    marginLeft: "10px"
  },
  card2: {
    //height: "383px",
    // [theme.breakpoints.down("xs")]: {
    //   width: "355px"
    // }
  },
  card3: {
    //height: "383px",
    [theme.breakpoints.down("sm")]: {}
  },
  Fieldsrootcontainer: {},
  Leavefield: {
    width: "90%"
    // [theme.breakpoints.down("xs")]: {
    //   width: "260px",

    //   justifyContent:"space-between"
    // }
  },
  subLeavefieldscontainer: {
    display: "flex",
    // justifyContent: "space-evenly",
    justifyContent: "flex-start",
    alignItems: "center",
    // flexDirection: "row",
    // marginLeft:"25px",
    // justifyContent:"space-between",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexWrap: "wrap"

      // justifyContent:"flex-start",
    }
  },

  subLeavefieldscontainer1: {
    display: "flex",
    // justifyContent: "space-evenly",
    justifyContent: "flex-start",
    alignItems: "center",
    // flexDirection: "row",
    // marginLeft:"25px",
    // justifyContent:"space-between",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexWrap: "wrap"

      // justifyContent:"flex-start",
    },
    //marginLeft: 12
  },

  subLeavefieldscontainer2: {
    display: "flex",
    // justifyContent: "space-evenly",
    justifyContent: "center",
    alignItems: "center",
    // flexDirection: "row",
    // marginLeft:"25px",
    // justifyContent:"space-between",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexWrap: "wrap"

      // justifyContent:"flex-start",
    },
    //marginLeft: 12
  },
  subLeavefields:{
    display: "flex",
    // justifyContent: "space-evenly",
    justifyContent: "center",
    alignItems: "center",
    // flexDirection: "row",
    marginLeft:"11px",
    // justifyContent:"space-between",
    [theme.breakpoints.down("md","lg")]: {
      display: "flex",
      flexWrap: "wrap"

      // justifyContent:"flex-start",
    },
     
  },

  Leavefields: {
    [theme.breakpoints.down("xs")]: {
      //  justifyContent:"space-between"
    }
  },
  footerappbar: {
    // width: "700px",
    marginLeft: "25px",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0px"
    }
  },
  Textfields: {
    //  [theme.breakpoints.down('xs')]:{
    //  },
    //  [theme.breakpoints.down('sm')]:{
    //   marginLeft:"80px"
    // }
  },

  calendarContainer: {
    direction: "column",
    [theme.breakpoints.only('lg')]: {
      direction: "row"
    }
  },

  iconstyle: {
    display: "flex",
    justifyContent: "space-between"
  },

  headerStyle: {
    color: theme.contentHeader. headerColor,
    fontSize: "22px",
  }
});
export default withStyles(style)(Leaveapplication);
