import React from 'react';
import ReactDOM from 'react-dom';
import injectTapEventPlugin from 'react-tap-event-plugin';
import { MuiThemeProvider, createMuiTheme, Theme } from '@material-ui/core/styles';
import { Provider, connect } from 'react-redux';
import { Router } from 'react-router';
import PropTypes from 'prop-types';
import { pinkTheme } from './store';
import Routes from './routes';
const ThemeContext = React.createContext({ changeTheme: (theme: Theme) => { } });


export class ThemeWrapper extends React.Component<any, any> {

  // static childContextTypes = {
  //   changeTheme: PropTypes.func
  // };

  constructor(props: any) {
    super(props);
    console.log(this.props);
    this.state = {
      theme: pinkTheme
    }
    this.changeTheme = this.changeTheme.bind(this);
  }

  componentDidMount() {
    // setTimeout(() => {
    //   this.setState({ theme: muiTheme })
    // }, 5000);
  }

  // getChildContext() {
  //   return { changeTheme: this.changeTheme };
  // }

  changeTheme = (theme: Theme | any) => {
    theme.contentHeader.headerColor = "red";
    this.setState({
      theme: theme
    })
  };

  render() {
    return (
      <ThemeContext.Provider value={{ changeTheme: this.changeTheme }}>
        <MuiThemeProvider theme={this.state.theme}>
          <Routes />
        </MuiThemeProvider>
      </ThemeContext.Provider>
    );
  }
}

ThemeWrapper.contextType = ThemeContext;
export const ThemeConsumer = ThemeContext.Consumer;