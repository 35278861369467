import { CssBaseline } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import WorkOffIcon from '@material-ui/icons/WorkOff';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import React, { Component } from "react";
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import HomePageFooterComponent from '../home/homepage-footer';
import HomePageMenu from '../home/homepage-header';
import './react-vertical-process.css';
const webIcon = require('../../../../www/assets/icons/web.png');

class ProcessComponent extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {

    }
  }

  componentDidUpdate(prevProps: any, prevState: any) {

  }

  render() {

    const { classes } = this.props;
    return (
      <React.Fragment>
        <CssBaseline />
        <HomePageMenu {...this.props} />
        <div style={{ marginTop: 40 }}></div>
        <div className={classes.root}>
          <Grid container direction="row" justify="center">
            <Grid spacing={24} alignItems="center" justify="center" container className={classes.grid}>
              <Grid container item xs={12} >

                <Grid item xs={12} style={{ height: 300, marginTop: 90 }}>
                  <div style={{ textAlign: 'center' }} className={classes.sample}>
                    {/* <Typography variant="h3" gutterBottom className={classes.marginbottom72}>
                        Our Services | What we do
                    </Typography> */}

                    <Typography variant="h1" gutterBottom color="primary" style={{ fontFamily: "'Montserrat', sans-serif", marginBottom: 30, fontSize: 90, fontWeight: 700 }}>
                      Our Approach
                    </Typography>

                    <Typography style={{ fontFamily: 'Montserrat', fontSize: 20, fontWeight: 300, paddingTop: 40 }} gutterBottom>
                      We think as you think and develop content perfectly tailored to your bussiness.
                      <br />We follow Agile Process to meet requirements in phases.
                    </Typography>

                  </div>
                </Grid>

                <Grid item xs={12} >
                  <div style={{ textAlign: 'center', marginBottom: 60 }} className={classes.sample}>
                    <ArrowDownwardIcon style={{ width: 40, height: 120, color: '#f05568' }} />
                  </div>
                </Grid>

                <Grid item xs={12} >
                  <div style={{ textAlign: 'center', marginBottom: 60 }} className={classes.sample}>
                    <Typography variant="h1" color="primary" gutterBottom style={{ fontFamily: "'Montserrat', sans-serif", marginBottom: 30, fontSize: 40, fontWeight: 300 }}>
                      Agile Process
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>

        <div style={{ backgroundColor: 'rgb(235, 236, 226)', position: 'relative', bottom: 46 }}>
          <VerticalTimeline >
            <VerticalTimelineElement
              className={`vertical-timeline-element--work ${classes.verticaltimelineelementrootcustom}`}
              date="let's talk"
              iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
              icon={<WorkOffIcon />}
            >

              <Typography className="vertical-timeline-element-title" variant="h3" gutterBottom style={{ fontFamily: "'Montserrat', sans-serif", fontSize: 28, fontWeight: 300 }}>
                Understanding Requirements
              </Typography>
              <Typography variant="title" gutterBottom style={{ marginTop: 30, color: '#2a405a', textAlign: 'left', marginLeft: 16, marginRight: 16, fontSize: 18 }}>
                Our people talk to you about your idea and gather all requirements. The first step in the process is we will create users stories for all your use cases and furthur will divide them in to tasks.
                <br />We use tools like Visual Studio Team Services (VSTS), Trello to plan Userstories and Tasks.
              </Typography>

            </VerticalTimelineElement>
            <VerticalTimelineElement
              className={`vertical-timeline-element--work ${classes.verticaltimelineelementrootcustom}`}
              date="research"
              iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
              icon={<WorkOffIcon />}
            >

              <Typography className="vertical-timeline-element-title" variant="h3" gutterBottom style={{ fontFamily: "'Montserrat', sans-serif", fontSize: 28, fontWeight: 300 }}>
                What fits best?
              </Typography>
              <Typography variant="title" gutterBottom style={{ marginTop: 30, color: '#2a405a', textAlign: 'left', marginLeft: 16, marginRight: 16, fontSize: 18 }}>
                With Design Thinking approach, with all user stories in place we research which technology stack to use which fits better for your app, thinking in all perspectives technology, time, cost, scaling and we will come up with the best final solution.
              </Typography>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className={`vertical-timeline-element--work ${classes.verticaltimelineelementrootcustom}`}
              date="UX design"
              iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
              icon={<WorkOffIcon />}
            >
              <Typography className="vertical-timeline-element-title" variant="h3" gutterBottom style={{ fontFamily: "'Montserrat', sans-serif", fontSize: 28, fontWeight: 300 }}>
                User Experience
              </Typography>
              <Typography variant="title" gutterBottom style={{ marginTop: 30, color: '#2a405a', textAlign: 'left', marginLeft: 16, marginRight: 16, fontSize: 18 }}>
                With tools like Sketch, Photoshop, Adobe XD and Illustrator we will create designs including prototyping where user not only see visually how the app looks like but also can interact with the designs by seeing the flow interactively.
              </Typography>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className={`vertical-timeline-element--education ${classes.verticaltimelineelementrootcustom}`}
              date="DB design"
              iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
              icon={<WorkOffIcon />}
            >
              <Typography className="vertical-timeline-element-title" variant="h3" gutterBottom style={{ fontFamily: "'Montserrat', sans-serif", fontSize: 28, fontWeight: 300 }}>
                DB Design
              </Typography>
              <Typography variant="title" gutterBottom style={{ marginTop: 30, color: '#2a405a', textAlign: 'left', marginLeft: 16, marginRight: 16, fontSize: 18 }}>
                Data is everything for any app. We give atmost time for DataBase design schemas. We follow all the optimizations to get best performance from DB.
              </Typography>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className={`vertical-timeline-element--work ${classes.verticaltimelineelementrootcustom}`}
              date="feedback"
              iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
              icon={<WorkOffIcon />}
            >
              <Typography className="vertical-timeline-element-title" variant="h3" gutterBottom style={{ fontFamily: "'Montserrat', sans-serif", fontSize: 28, fontWeight: 300 }}>
                Feedback
              </Typography>
              <Typography variant="title" gutterBottom style={{ marginTop: 30, color: '#2a405a', textAlign: 'left', marginLeft: 16, marginRight: 16, fontSize: 18 }}>
                With the designs and Database schemas in place we will get User feedback to proceed for actual development of App.
              </Typography>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className={`vertical-timeline-element--education ${classes.verticaltimelineelementrootcustom}`}
              date="development"
              iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
              icon={<WorkOffIcon />}
            >
              <Typography className="vertical-timeline-element-title" variant="h3" gutterBottom style={{ fontFamily: "'Montserrat', sans-serif", fontSize: 28, fontWeight: 300 }}>
                Developing UI and API's
              </Typography>
              <Typography variant="title" gutterBottom style={{ marginTop: 30, color: '#2a405a', textAlign: 'left', marginLeft: 16, marginRight: 16, fontSize: 18 }}>
                We start with API design and implement Swagger on top of the API's to provide clear picture on the entities to the UI developers. The interaction between UI and API developers will happen through Swagger. We use tools like Visual Studio Code for UI as well as Node API development.
              </Typography>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className={`vertical-timeline-element--education ${classes.verticaltimelineelementrootcustom}`}
              date="production"
              iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
              icon={<WorkOffIcon />}
            >

              <Typography variant="title" gutterBottom style={{ marginTop: 30, color: '#2a405a', textAlign: 'left', marginLeft: 16, marginRight: 16, fontSize: 18 }}>
                We use docker containers to move your entire app to AWS | Azure | Digital Ocean servers. We use dockers as this gives flexibility for scaling the app. We finally orvide CI/CD for your app to deploy your Containarized app to Production servers.
              </Typography>
            </VerticalTimelineElement>
          </VerticalTimeline>
        </div>
        <HomePageFooterComponent />
      </React.Fragment >
    );
  }
}

const styles = (theme: any) => ({
  sample: {
    [theme.breakpoints.down('md')]: {
      marginBottom: 72,
    }
  },

  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 5,
    paddingBottom: theme.spacing.unit * 0,
    flexGrow: 1,
    marginTop: 30,
    backgroundColor: 'white'
  },
  grid: {
    width: '75%',
    marginTop: 40,
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 20px)'
    }
  },
  Att: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 5,
    paddingBottom: theme.spacing.unit * 0,
  },
  verticaltimelineelementrootcustom: {
    paddingBottom: '90px !important'
  }
});

export default withStyles(styles)(ProcessComponent);
