import React, { Component } from "react";
import { Formik, FormikActions, FormikProps, Field, FieldProps } from 'formik';
import withStyles from "@material-ui/core/styles/withStyles";
import { CustomForm } from "./form";
import Paper from "@material-ui/core/Paper";
import * as Yup from "yup";

const styles = (theme: any) => ({
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: "flex",
    //flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 5}px ${theme.spacing.unit * 5}px ${theme
      .spacing.unit * 5}px`
  },
  container: {
    maxWidth: "200px"
  }
});

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  email: Yup.string()
    .email("Enter a valid email")
    .required("Email is required"),
  password: Yup.string()
    .min(8, "Password must contain atleast 8 characters")
    .required("Enter your password"),
  confirmPassword: Yup.string()
    .required("Confirm your password")
    .oneOf([Yup.ref("password")], "Password does not match")
});

class InputForm extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    const classes = this.props;
    const values = { name: '', email: '', confirmPassword: '', password: '' };
    return (
      <React.Fragment>
        <div className={classes.container}>
          <Paper elevation={1} className={classes.paper}>
            <h1>Form</h1>
            <Formik
              render={props => <CustomForm {...props} />}
              initialValues={values}
              validationSchema={validationSchema}
              onSubmit={(values: any, actions: FormikActions<any>) => {
                // console.log({ values, actions });
                // alert(JSON.stringify(values, null, 2));
                actions.setSubmitting(false);
                this.props.onSubmit(values);
              }}
            />
          </Paper>
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(InputForm);
