import React from 'react';
import {connect} from 'react-redux';
import Employee from '../../components/viewcomponents/profile/employee-profile';

class ProfileContainer extends React.Component <any,any>{
    constructor(props:any){
        super(props)
        console.log(this.props)
    }
    componentDidMount(){

    }
    render(){
        return(
            <React.Fragment>
                <div>
                    <Employee />
                </div>
            </React.Fragment>
        )
    }
};
const mapstatetoprops=(state:any)=>{
    return{
        
        ...state
    } 
}
export default connect (mapstatetoprops)(ProfileContainer);