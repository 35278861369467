import { Dispatch } from 'redux';
import {
  types,
  ITopics
} from './model';
import Action from '../IAction';
import { ActionType } from 'typesafe-actions';

export function setTopics(data: ITopics): Action<ITopics> {
  return {
    type: types.SET_THEME_PROPERTIES,
    payload: {
      topicName: data.topicName,
      currentDateTime: data.currentDateTime
    }
  }
}

export function getThemeProperties() {
  var date = new Date();
  var data: ITopics = {
    topicName: "Topic1",
    currentDateTime: date.getDate()
  }



  //After calling API pass data to update reducer (STORE)
  return async (dispatch: any) => {
    // return new Promise<any>((resolve, reject) => {
    //   dispatch(setTopics(data));
    // });

    return data;

  }
}