import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DashBoard from '@material-ui/icons/Dashboard';
import Home from '@material-ui/icons/Home';
import Input from '@material-ui/icons/Input';
import MailIcon from '@material-ui/icons/Mail';
import MenuIcon from '@material-ui/icons/Menu';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import Settings from '@material-ui/icons/Settings';
import Today from '@material-ui/icons/Today';
import Work from '@material-ui/icons/Work';
import WorkOffIcon from '@material-ui/icons/WorkOff';
import classNames from 'classnames';
import React from 'react';
import { Link, Route } from 'react-router-dom';
import DashboardContainer from '../../Dashboard/dashboardcontainer';
import startcontainer from '../../employee/startcontainer';
import leaveContainer from '../../leave/leavecontainer';
import EmployeeContainer from '../../profile/profilecontainer';
import TestingCSS from '../../testingcss/testingcss';
import TopicsContainer from '../../Topics/TopicsContainer';
import SettingsContainer from '../../user/settings/SettingsContainer';

import endsession from '../../../components/viewcomponents/employee1/endsession';


const About = ({ match }: any) => (
  <div>
    <h2>{`${match.url}`}</h2>
  </div>
)

class ResponsiveDrawer extends React.Component<any, any> {
  renderLink = (itemProps: any) => <Link to='/app/topics' {...itemProps} />;
  renderProfile = (itemProps: any) => <Link to='/app/profile' {...itemProps} />;
  renderHome = (itemProps: any) => <Link to='/' {...itemProps} />;
  renderLogin = (itemProps: any) => <Link to='/login' {...itemProps} />;
  renderDashboard = (itemProps: any) => <Link to='/app/dashboard'{...itemProps} />
  renderLeave = (itemProps: any) => <Link to='/app/leave'{...itemProps} />
  renderTesting = (itemProps: any) => <Link to='/app/testing'{...itemProps} />
  renderStartSession = (itemProps: any) => <Link to='/app/StartSession'{...itemProps} />
  renderEndSession = (itemProps: any) => <Link to='/app/EndSession'{...itemProps} />
  renderSettings = (itemProps: any) => <Link to='/app/usersettings' {...itemProps} />;
  constructor(props: any) {
    super(props);
    console.log(this.props.match.url);
  }
  state = {
    open: false,
  };

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes, theme } = this.props;

    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={classNames(classes.appBar, {
            [classes.appBarShift]: this.state.open,
          })}

        >
          <Toolbar disableGutters={!this.state.open}>
            <IconButton

              aria-label="Open drawer"
              onClick={this.handleDrawerOpen}
              className={classNames(classes.menuButton, {
                [classes.hide]: this.state.open,
              })}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h5" color="inherit" noWrap>
              Work Day
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          className={classNames(classes.drawer, {
            [classes.drawerOpen]: this.state.open,
            [classes.drawerClose]: !this.state.open,
          })}
          classes={{
            paper: classNames({
              [classes.drawerOpen]: this.state.open,
              [classes.drawerClose]: !this.state.open,
            }),
          }}
          open={this.state.open}

        >
          <div className={classes.toolbar}>
            <div>Icon</div>
            <IconButton onClick={this.handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>

          </div>



          <List>
            <ListItem button key="login" component={this.renderLogin} style={{ height: 50 }}>
              <ListItemIcon>{<Input style={{ color: 'green' }} />}</ListItemIcon>
              <ListItemText primary="Login" />
            </ListItem>
            {/* <ListItem button key="home" component={this.renderHome}>
              <ListItemIcon>{<Home style={{ color: 'red' }} />}</ListItemIcon>
              <ListItemText primary="Home" />
            </ListItem> */}
            <ListItem button key="dashboard" component={this.renderDashboard} style={{ height: 70 }}>
              <ListItemIcon>{<DashBoard style={{ color: 'purple' }} />}</ListItemIcon>
              <ListItemText primary="DashBoard" />
            </ListItem>
            <ListItem button key="WorkOffIcon" component={this.renderLeave} style={{ height: 70 }}>
              <ListItemIcon>{<WorkOffIcon style={{ color: 'green' }} />}</ListItemIcon>
              <ListItemText primary="Apply Leave" />
            </ListItem>
            <ListItem button key="Topics" component={this.renderLink}>
              <ListItemIcon>{<InboxIcon style={{ color: 'blue' }} />}</ListItemIcon>
              <ListItemText primary="Topics" />
            </ListItem>
            <ListItem button key="Profile" component={this.renderProfile} style={{ height: 70 }}>
              <ListItemIcon>{<MailIcon style={{ color: 'grey' }} />}</ListItemIcon>
              <ListItemText primary="My Profile" />
            </ListItem>
            {/* {['Topics', 'Send email'].map((text, index) => (
              <ListItem button key={text} component={this.renderLink}>
                <ListItemIcon>{index % 2 === 0 ? <InboxIcon style={{ color: 'blue' }} /> : <MailIcon style={{ color: 'grey' }} />}</ListItemIcon>
                <ListItemText primary={text} />
              </ListItem>
            ))} */}
            <ListItem button key="StartSession" component={this.renderStartSession} style={{ height: 70 }}>
              <ListItemIcon>{<Work style={{ color: '#bc2599' }} />}</ListItemIcon>
              <ListItemText primary="Attendence" />
            </ListItem>
            <ListItem button key="EndSession" component={this.renderEndSession} style={{ height: 70 }}>
              <ListItemIcon>{<Today style={{ color: 'green' }} />}</ListItemIcon>
              <ListItemText primary="EndSession" />
            </ListItem>
            <ListItem button key="Testing" component={this.renderTesting} style={{ height: 70 }}>
              <ListItemIcon>{<MailIcon style={{ color: 'grey' }} />}</ListItemIcon>
              <ListItemText primary="Testing" />
            </ListItem>
          </List>
          <Divider />
          <List>
            {['Settings'].map((text, index) => (
              <ListItem button key={text} component={this.renderSettings}>
                <ListItemIcon>{index % 2 === 0 ? <Settings style={{ color: 'cyan' }} /> : <MailIcon />}</ListItemIcon>
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List>
        </Drawer>
        <main className={classes.content}>
          <div className={classes.toolbar} />

          <Route path='/app/about' component={About} />
          {/* <Route path="/app/about" component={About} /> */}
          <Route path='/app/topics' component={TopicsContainer} />
          <Route path='/app/usersettings' component={SettingsContainer} />
          <Route path='/app/dashboard' component={DashboardContainer} />
          <Route path='/app/leave' component={leaveContainer} />
          <Route path='/app/profile' component={EmployeeContainer} />
          <Route path='/app/testing' component={TestingCSS} />
          <Route path='/app/startsession' component={startcontainer} />
          <Route path='/app/endsession' component={endsession}></Route>

        </main>
      </div>
    );
  }
}

const drawerWidth = 240;

const styles = (theme: any): any => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing.unit * 7 + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 9 + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },
});


// ResponsiveDrawer.propTypes = {
//   classes: PropTypes.object.isRequired,
//   theme: PropTypes.object.isRequired,
// };

export default withStyles(styles, { withTheme: true })(ResponsiveDrawer);
