export const types = {
  LOGIN: 'LOGIN',
  LOGIN_RECEIVED: 'LOGIN_RECEIVED',
  LOGIN_ERROR: 'LOGIN_ERROR',
  LOGOUT: 'LOGOUT',
  SET_LOGIN_LOADING: 'SET_LOGIN_LOADING',
  SET_TOASTR_LOADING: 'SET_TOASTR_LOADING',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  SET_TOASTR_MESSAGE: 'SET_TOASTR_MESSAGE'
};

export type ILoginUser = {
  loginUser: {}
};
export type ILoginReceived = {
  user: {}
};
export type ILoginSuccess = {
  isSuccess: boolean
};
export type ISetLoader = {
  loading: boolean
};

export type ILoginFormState = {
  email: string,
  password: string,
  loading?: boolean,
  loginSuccess?: boolean,
  user?: {},
  showToastr: boolean,
  message: string,
  roleType: string
}

export class LoginFormRequestData {
  username: string = "";
  password: string = "";
}
export type LoginFormResponseData = {

}

