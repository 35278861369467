import { types, ILoginFormState } from './model';
import Immutable from 'seamless-immutable';
import Action from '../IAction';

const initialState: ILoginFormState = Immutable<ILoginFormState>({
  email: '',
  password: '',
  loading: false,
  loginSuccess: false,
  user: {},
  showToastr: false,
  message: '',
  roleType: ''
});

export default function common(state = initialState, action: Action<any>) {
  switch (action.type) {
    case types.LOGIN:
      return Object.assign({}, state, {
        user: action.payload.LoginUser
      });
    case types.LOGIN_RECEIVED:
      return Object.assign({}, state, {
        user: action.payload.user,
      });
    case types.LOGIN_SUCCESS:
      return Object.assign({}, state, {
        loginSuccess: action.payload.isSuccess
      });
    case types.LOGIN_ERROR:
      return state;
    case types.LOGOUT:
      return state;
    case types.SET_LOGIN_LOADING:
      return Object.assign({}, state, {
        loading: action.payload.loading
      });
    default:
      return state;
  }
}
