import React from 'react';
import {connect} from 'react-redux';
import Leaveapplication from '../../components/viewcomponents/leave/leave';
import StartSession from '../../components/viewcomponents/employee/startsession';

class Sessioncontainer extends React.Component <any,any>{
    constructor(props:any){
        super(props)
        console.log(this.props)
    }
    componentDidMount(){

    }
    render(){
        return(
            <React.Fragment>
                <div>
                    <StartSession />
                </div>
            </React.Fragment>
        )
    }
};
const mapstatetoprops=(state:any)=>{
    return{
        
        ...state
    } 
}
export default connect (mapstatetoprops)(Sessioncontainer);