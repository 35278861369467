import React from 'react'
import axios from 'axios'
import { API } from '../../constants/dashapi'

export async function LoadDataInTables() {

    var response = await axios.get(`http://dummy.restapiexample.com/api/v1/employees`);

    return async (dispatch: any) => {
        return response;
    }
}


