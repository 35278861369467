import React, { Component } from "react";
import PropTypes, { string } from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Person from '@material-ui/icons/Person';
import { Form } from "formik";
import { Card, Grid, CssBaseline, CardHeader, Avatar, Divider } from "@material-ui/core";
import rectangle from '../../../../www/assets/images/rectangle.jpg';
import group2 from '../../../../www/assets/images/group2.png';

class LoginForm extends Component<any, any> {
    constructor(props: any) {
      super(props);
      console.log(this.props);
  
      this.state = {
        name: '',
        password: ''
      }
    }
    

render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
          <CssBaseline />
          <div className={classes.root}>
                      <img src={rectangle} className={classes.background} />
            <Grid container xs={12}direction="row" justify="center" className={classes.Card}>
                <Grid alignItems="center" justify="center" container direction='column' className={classes.grid}>
                    <Grid item xs={12} xl={12} lg={12}className={classes.grid1}>
                         <img src={group2} className={classes.group2} />
                    </Grid>
                    <Grid justify="center" alignItems="center"  item xl={12}xs={12}lg={12} className={classes.grid2}>
                    <Form className={classes.form} >
                        <Typography style={{marginTop:'20px',fontWeight:'normal',fontSize:'40px',color:'#1ea7a6'}}>SIGN IN</Typography>
                    <FormControl margin="normal" >
                        <TextField
                          id="standard-name"
                          label="Username"
                          type="Email"
                          name="Email"
                          className={classes.textField}
                          value={this.state.name}
                          margin="normal"
                        />
                    </FormControl>
                      <br/>
                    <FormControl margin="normal">
                      <TextField
                        id="standard-password-input"
                        label="Password"
                        className={classes.textField}
                        type="password"
                        autoComplete="current-password"
                        margin="normal"
                      />

                    </FormControl>
                    <br></br>
                    <Button type="submit" variant="contained" color="primary" className={classes.submit}>
                      Sign In
                    </Button>
                  </Form>

                    </Grid>
                </Grid>
            </Grid>
          </div>
      </React.Fragment>
    );
}
} 

const styles: any = (theme: any) => ({
    root: {
      flexGrow: 1,
    },
    background:{
        width:"100%",
        height: '100%',
        opacity:'0.8',
        position: 'fixed',
       
    },
    grid:{
      width:'50%',
      overflowY: 'hidden',
      height:'410px', 
      position:'relative',
      top:'150px',
      [theme.breakpoints.only('xs')]: {
        width:'100%',
        height:'320px',
       
      },
    },
    grid1:{
      width:'50%',
      // border:'2px sold',
      backgroundColor:'#1ea7a6',
      textAlign:'center',
    
    [theme.breakpoints.only('xs')]: {
      height:'0',
      width:'0',
    },
    },
    grid2:{
      width:'50%',
      // border:'2px solid',
      textAlign:'center',
      backgroundColor:'white',
      height:'400'
      
    },
    Card:{
      borderRadius:'30px',
      width:'100%'
    },
    group2:{
      paddingTop: '140px',
      [theme.breakpoints.only('xs')]: {
        height:'0',
        width:'0',
      },
     
    }

});

export default withStyles(styles)(LoginForm);