import Paper from '@material-ui/core/Paper';
import { withStyles } from "@material-ui/core/styles";
import Tab from '@material-ui/core/Tab';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tabs from '@material-ui/core/Tabs';
import React, { Component } from "react";

class Dashboard extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      Rows: []
    }
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (this.props.tableData !== prevProps.tableData) {
      this.setState({
        Rows: this.props.tableData
      })
    }
  }

  render() {

    const { classes } = this.props;
    return (
      <div>
        <div className={classes.root}>
          <h1 style={{ color: "darkcyan" }}>Employess  Dash Board</h1>
        </div>
        <Tabs
          value={this.state.value}
          indicatorColor="primary"
          textColor="primary" >
          <Tab label="Attendance Report" />
          <Tab label="Work input Updates" />
        </Tabs>
        <hr />
        <Paper>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell align="right">ID</TableCell>
                <TableCell align="right">Employee Name</TableCell>
                <TableCell align="right">Employee Salary</TableCell>
                <TableCell align="right">Employee Age</TableCell>
                <TableCell align="right">ProfileDp</TableCell>
                <TableCell align="right">Duration</TableCell>
                <TableCell align="right">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.Rows.map((Row: any) => (
                <TableRow >
                  <TableCell align="right">{Row.id}</TableCell>
                  <TableCell align="right">{Row.employee_name}</TableCell>
                  <TableCell align="right">{Row.employee_salary}</TableCell>
                  <TableCell align="right">{Row.employee_age}</TableCell>
                  <TableCell align="right">{Row.profile_image}</TableCell>
                  <TableCell align="right">{Row.Duration}</TableCell>
                  <TableCell align="right">{Row.Status}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </div>
    );
  }
}

const styles = (theme: any) => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 5,
    paddingBottom: theme.spacing.unit * 0,

  },
  Att: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 5,
    paddingBottom: theme.spacing.unit * 0,
  }
});

export default withStyles(styles)(Dashboard);
