import { types } from './model';
import Immutable from 'seamless-immutable';
import Action from '../IAction';

const initialState: any = Immutable<any>({
  topicName: '',
  currentDateTime: null
});

export default function topic(state = initialState, action: Action<any>) {
  switch (action.type) {
    case types.SET_TOPICS:
      return Object.assign({}, state, {
        topicName: action.payload.topicName,
        currentDateTime: action.payload.currentDateTime,
      });
    default:
      return state;
  }
}

