import { CssBaseline } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import UserSettingsComponent from '../../../components/viewcomponents/user/settings/user-settings-component';

class SettingsContainer extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    console.log(this.props);
  }

  render() {
    const { classes, theme } = this.props;

    return (
      <div>
        <CssBaseline />
        <main>
          <UserSettingsComponent match={this.props.match} />
        </main>

      </div>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    ...state
  }
};

const drawerWidth = 240;

const styles = (theme: any): any => ({
 
});

export default connect(mapStateToProps)(SettingsContainer);
//export default withStyles(styles, { withTheme: true }(connect(mapStateToProps)(SettingsContainer)));