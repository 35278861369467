import { CssBaseline } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import UserSettingsTheme from '../../../components/viewcomponents/user/settings/user-settings-theme';
import * as UserSettingsActions from "../../../redux/usersettings/actions";

class SettingsThemesContainer extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  getThemeProperties = async (changeTheme: Function, theme: any) => {
    console.log("Change themes clicked");
   
    this.props.dispatch(UserSettingsActions.getThemeProperties()).then((data: any) => {
        console.log(data);
        changeTheme(theme);
    });

  }

  render() {
    const { classes, theme } = this.props;

    return (
      <div>
        <CssBaseline />
        <main>
          <UserSettingsTheme
             {...this.props}
             match={this.props.match} 
             getThemeProperties={this.getThemeProperties} />
        </main>

      </div>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    ...state
  }
};

// const mapDispatchToProps = (dispatch: any) => {
//     return {
//       onPlusClick: () => dispatch(getThemeProperties())
//     };
// }

const drawerWidth = 240;

const styles = (theme: any): any => ({
 
});

export default connect(mapStateToProps)(SettingsThemesContainer);
//export default withStyles(styles, { withTheme: true }(connect(mapStateToProps)(SettingsContainer)));