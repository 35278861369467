import React from 'react';
import { Route } from 'react-router';
import { Link } from 'react-router-dom';
import { Button, CssBaseline } from '@material-ui/core';
import PropTypes from 'prop-types';
import { ThemeConsumer } from '../../../themewrapper';
import InputForm from './testing-formik/InputForm';

export default class TestingComponent extends React.Component<any, any> {

  constructor(props: any) {
    super(props);
  }

  componentDidMount() {

  }

  render() {
    return (
      <React.Fragment>
        <CssBaseline />
        <InputForm onSubmit={(email: any) => {
          console.log(email);
        }} />
      </React.Fragment>
    );
  }
}