import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { withRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import logo from '../../../../www/assets/images/mobile-image.png';
import HomeWhatWeDoComponent from './homepage-whatwedo';

const backgroundShape = require('../../../images/shape.svg');
const opensource = require('../../../../www/assets/images/opensource.png')

class HomePageFooterComponent extends Component<any, any> {

  constructor(props: any) {
    super(props);
  }
  state = {
  };

  componentDidMount() { }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <CssBaseline />
        {/* <Topbar /> */}
        <div className={classes.root}>
          <Grid container direction="row" justify="center">

            <Grid container item xs={12} >
              <Grid item xs={12} lg={3} md={12} xl={3} style={{ backgroundColor: 'rgb(80, 103, 140)', height: 500 }} alignItems="center" justify="center">
                <Grid container item xs={12} style={{ marginTop: 60 }}>
                  <Grid item xs={1} lg={1} xl={1} md={1}>
                  </Grid>
                  <Grid item xs={10} lg={10} xl={10} md={10} style={{ marginTop: 60 }}>
                    <Typography variant="h6" gutterBottom style={{ color: 'white' }}>
                      Email us on :-
                    </Typography>
                    <Typography variant="h5" gutterBottom style={{ marginTop: 30, lineHeight: 1.3, marginBottom: 30, fontSize: 30, fontWeight: 100, color: 'white' }}>
                      info@dfftech.com
                    </Typography>
                  </Grid>
                  <Grid item xs={1} lg={1} xl={1} md={1}>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} lg={6} xl={6} md={12} style={{ backgroundColor: 'rgb(235, 236, 226)', height: 500 }} alignItems="center" justify="center">

                <Grid container item xs={12} >
                  <Grid item xs={1} lg={3} xl={3} md={3}>
                  </Grid>
                  <Grid item xs={10} lg={6} xl={6} md={6} style={{ marginTop: 60 }}>
                    <Typography variant="h6" gutterBottom>
                      DFFTech stays at...
                    </Typography>
                    <Typography variant="h2" gutterBottom style={{ fontFamily: "'Montserrat', sans-serif", marginTop: 30, lineHeight: 1.3, marginBottom: 30, fontSize: 40, fontWeight: 100 }}>
                      Plot No: 1-10/9/24,
                      <br /> Manikonda,
                      <br />Near Andhra Bank,
                      <br />Pin - 500089,
                      <br />Hyderabad,
                      <br />Telangana, India.
                    </Typography>
                  </Grid>
                  <Grid item xs={1} lg={3} xl={3} md={3}>
                  </Grid>
                </Grid>

              </Grid>

              <Grid item xs={12} lg={3} xl={3} md={12} style={{ backgroundColor: 'rgb(61, 60, 84)', height: 500 }} alignItems="center" justify="center">

                <Grid container item xs={12} style={{ marginTop: 60 }}>
                  <Grid item xs={1} lg={1} xl={1} md={1}>
                  </Grid>
                  <Grid item xs={10} lg={10} xl={10} md={10} style={{ marginTop: 60, marginLeft: 12 }}>
                    <Typography variant="h6" gutterBottom style={{ color: 'white' }}>
                      You can call us on :-
                    </Typography>
                    <Typography variant="h5" gutterBottom style={{ marginTop: 30, lineHeight: 1.3, marginBottom: 30, fontSize: 30, fontWeight: 100, color: 'white' }}>
                      +919381038898
                    </Typography>
                  </Grid>
                  <Grid item xs={1} lg={1} xl={1} md={1}>
                  </Grid>

                </Grid>
              </Grid>
            </Grid>

            <Grid container item xs={12} >

              <Grid item xs={12} lg={12} xl={12} md={12} style={{ backgroundColor: 'black', height: 300 }}>
              </Grid>



            </Grid>
            {/* </Grid> */}
          </Grid>
        </div>
      </React.Fragment>
    )
  }
}

const styles: any = (theme: any) => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#f2f6ff',
    marginTop: 60
    //overflow: 'hidden',
    //background: `url(${backgroundShape}) no-repeat`,
    //backgroundSize: 'cover',
    //backgroundPosition: '0 400px',
    //paddingBottom: 200
  },
  grid: {
    width: '100%',
    marginTop: 40,
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 20px)'
    }
  },
  paper: {
    padding: theme.spacing.unit * 3,
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  rangeLabel: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: theme.spacing.unit * 2
  },
  topBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 32
  },
  outlinedButtom: {
    textTransform: 'uppercase',
    margin: theme.spacing.unit
  },
  actionButtom: {
    textTransform: 'uppercase',
    margin: theme.spacing.unit,
    width: 152
  },
  blockCenter: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center'
  },
  block: {
    padding: theme.spacing.unit * 2,
  },
  box: {
    marginBottom: 40,
    height: 65
  },
  inlining: {
    display: 'inline-block',
    marginRight: 10
  },
  buttonBar: {
    display: 'flex'
  },
  alignRight: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  noBorder: {
    borderBottomStyle: 'hidden'
  },
  loadingState: {
    opacity: 0.05
  },
  loadingMessage: {
    position: 'absolute',
    top: '40%',
    left: '40%'
  },
  sample: {
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      marginBottom: 72,
    }
  },
  marginbottom72: {
    marginBottom: 72,
    fontWeight: 600
  },
  boxcontent: {
    //width: 420,
    //height: 160,
    // backgroundColor: '#ffffff',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'calc(100% - 80%)',
      width: '60%'
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 'calc(100% - 94%)',
      width: '90%'
    }
  },
  skillsImage: {
    width: '720px !important',
    [theme.breakpoints.up('md')]: {
      width: '80%'
    },
  }
});

//export default HomeComponent;

export default withStyles(styles)(HomePageFooterComponent);
