import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import { Link, Route } from 'react-router-dom';
import { ActionType } from 'typesafe-actions';
import { Button, Grid, Typography } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import withStyles from '@material-ui/core/styles/withStyles';
import * as LoginActions from "../../redux/loginform/actions";
import TopicsComponent from '../../components/viewcomponents/topics/topics-component';
import SocialLoginFormComponent from '../../components/common/login/sociallogin-form-component';
import { callbackify } from 'util';
import LoginFormComponent from '../../components/viewcomponents/login/login-form-component';
import LoginForm from '../../components/viewcomponents/signin/loginform';

class LoginFormContainer extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    console.log(this.props);
  }

  componentDidMount() {

  }

  handleEmailLogin = async (event: any) => {
    console.log(event);
    this.props.dispatch(LoginActions.loginWithEmail(event)).then((data: any) => {
      console.log(data);
    })
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <CssBaseline />
        <div className={classes.root} >
          <Grid container direction="row" justify="center">
            <Grid alignItems="center" justify="center" container className={classes.grid}>
              <Grid container item xs={12} lg={12} style={{ height: 'calc(100vh)' }}>
                <LoginForm handleEmailLogin={this.handleEmailLogin} />
                {/* <Grid item xs={12} lg={6} md={6}>
                  <div className={classes.boxcontent}>
                  </div>
                </Grid>

                <Grid item xs={12} lg={6} md={6}>
                  <div className={classes.sample}>
                    <div>
                      <SocialLoginFormComponent />
                    </div>
                  </div>
                </Grid> */}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    )
  }
}

const styles: any = (theme: any) => ({
  root: {
    //flexGrow: 1,
    backgroundColor: '#ffffff',
    //overflow: 'hidden',
    //background: `url(${backgroundShape}) no-repeat`,
    //backgroundSize: 'cover',
    //backgroundPosition: '0 400px',
    //paddingBottom: 200
  },
  grid: {
    width: '100%',
    //marginTop: 40,
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 20px)'
    }
  },
  paper: {
    padding: theme.spacing.unit * 3,
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  rangeLabel: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: theme.spacing.unit * 2
  },
  topBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 32
  },
  outlinedButtom: {
    textTransform: 'uppercase',
    margin: theme.spacing.unit
  },
  actionButtom: {
    textTransform: 'uppercase',
    margin: theme.spacing.unit,
    width: 152
  },
  blockCenter: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center'
  },
  block: {
    padding: theme.spacing.unit * 2,
  },
  box: {
    marginBottom: 40,
    height: 65
  },
  inlining: {
    display: 'inline-block',
    marginRight: 10
  },
  buttonBar: {
    display: 'flex'
  },
  alignRight: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  noBorder: {
    borderBottomStyle: 'hidden'
  },
  loadingState: {
    opacity: 0.05
  },
  loadingMessage: {
    position: 'absolute',
    top: '40%',
    left: '40%'
  },
  sample: {
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      marginBottom: 72,
    }
  },
  marginbottom73: {
    marginBottom: 72,
    fontWeight: 600,
    color: '#2a405a',
    //borderBottom: `1px solid ${theme.palette.grey['A100']}`,
    // '&::after': {
    //   content: `''`,
    //   border: '15px solid #f2f2f6',
    //   display: 'inline-block',
    //   position: 'absolute',
    //   top: '-20px',
    //   bottom: '-20px',
    //   right: '-35%',
    //   width: '80%',
    //   zIndex: -1
    // },
    // '&::before': {
    //   content: `''`,
    //   border: '15px solid #f2f2f6',
    //   display: 'inline-block',
    //   position: 'absolute',
    //   top: '-20px',
    //   bottom: '-20px',
    //   right: '-35%',
    //   width: '80%',
    //   zIndex: -1
    // }
  },
  marginbottom72: {
    marginBottom: 72,
    fontWeight: 600,
    color: '#2a405a'
  },
  boxcontent: {
    width: '100%',
    height: '100%',
    //backgroundColor: '#f2f9ff',
    textAlign: 'left',
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'calc(100% - 80%)',
      width: '60%'
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 'calc(100% - 94%)',
      width: '90%'
    }
  },
  headerGrid: {
    width: '75%',
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 20px)'
    }
  },
  borderLine: {
    height: 6,
    width: 200,
    backgroundColor: '#4c9dd8'
  },
  loginSidebg: {
    color: theme.palette.text.primary,
  }
});


const mapStateToProps = (state: any) => {
  return {
    ...state
  }
};

export default connect(mapStateToProps)(withStyles(styles)(LoginFormContainer));