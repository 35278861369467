import React from 'react'
import { connect } from 'react-redux'
import Dashboard from '../../components/viewcomponents/dashboard/dashboard'
import * as DasahboardActions from '../../redux/Dashboard/actions'

class DashboardContainer extends React.Component<any, any>{
  constructor(props: any) {
    super(props);
    this.state = {
      tableData: []
    }
  }

  async componentDidMount() {
    this.props.dispatch(await DasahboardActions.LoadDataInTables()).then((response: any) => {
      console.log(response.data);
      this.setState({
        tableData: response.data
      });
    });
  }

  render() {
    return (
      <React.Fragment>
        <div>
          <Dashboard
            tableData={this.state.tableData}
          />
        </div>
      </React.Fragment>
    )
  }
};
const mapstatetoprops = (state: any) => {
  return {
    ...state
  }
}
export default connect(mapstatetoprops)(DashboardContainer);