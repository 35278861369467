import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import './testingcss.css';
import TestingComponent from '../../components/viewcomponents/testing/testing-component';

class TestingCSS extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    console.log(this.props);
  }

  render() {
    return (
      // <body>
      //   <div className="container">
      //     <div className="box-orange"></div>
      //     <div className="box-blue"></div>
      //   </div>
      // </body>
      <React.Fragment>
        <TestingComponent />
      </React.Fragment>
    )
  }
}


const mapStateToProps = (state: any) => {
  return {
    ...state
  }
};

export default connect(mapStateToProps)(TestingCSS);