import React from 'react';
import ReactDOM from 'react-dom';
import injectTapEventPlugin from 'react-tap-event-plugin';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { Provider, connect } from 'react-redux';
import { Router } from 'react-router';
import { ThemeWrapper } from './themewrapper';

import { store } from './store';
import Routes from './routes';
//const history = syncHistoryWithStore(browserHistory, store);

class App extends React.Component {
  componentDidMount() {

  }
  render() {
    return (
      <Provider store={store}>
        {/* <MuiThemeProvider theme={this.state.theme}>
          <Routes />
        </MuiThemeProvider> */}

        <ThemeWrapper />
      </Provider>
    );
  }
}
// injectTapEventPlugin();
// ReactDOM.render(<App />, document.getElementById('app'));
export default App;


//export default connect()(App);