import { Avatar, Card, CardHeader, CssBaseline, Divider, Grid, List, ListItem, ListItemText, withStyles } from '@material-ui/core';
import Settings from '@material-ui/icons/Settings';
import React from 'react';
import { Route } from 'react-router';
import { Link } from 'react-router-dom';
import SettingsThemesContainer from '../../../../containers/user/settings/SettingsThemeContainer';

class UserSettingsComponent extends React.Component<any, any> {
  renderSettings = (itemProps: any) => <Link to='/app/usersettings/changetheme' {...itemProps} />;
  constructor(props: any) {
    super(props);
  }

  componentDidMount() {

  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <CssBaseline />
        {/* <Topbar /> */}
        <div className={classes.root} style={{marginTop: 20}}>

          <Grid container direction="row" justify="center">
            <Grid spacing={24} alignItems="flex-start" justify="center" container className={classes.grid}>

              <Grid item xs={12} md={6} lg={4} style={{ marginTop: -18 }}>
                <Card className={classes.card} style={{ textAlign: 'left', minHeight: 'calc(100vh - 120px)' }}>
                  <CardHeader
                    avatar={
                      <Avatar aria-label="Recipe" className={classes.avatar}>
                        <Settings />
                      </Avatar>
                    }
                    title="Settings"
                    subheader="System Settings"
                    classes={{
                      title: classes.cardHeader,
                    }}
                  />

                  <Divider />

                  <List>
                    {['ChangeTheme', 'ChangeLogo'].map((text, index) => (
                      <ListItem button key={text} component={this.renderSettings}>

                        <ListItemText primary={text} />
                      </ListItem>
                    ))}
                  </List>


                </Card>

              </Grid>


              <Grid item xs={12} md={6} lg={8} style={{ marginTop: -18 }}>


                {/* {this.props.children} */}


                {/* <Route exact path={this.props.match.url} render={() => (
                  <Card className={classes.card} style={{ textAlign: 'left', minHeight: 'auto' }}>
                    <CardHeader
                      avatar={
                        <Avatar aria-label="Recipe" className={classes.avatar}>
                          <Settings />
                        </Avatar>
                      }
                      title="Settings"
                      subheader="System Settings"
                      classes={{
                        title: classes.cardHeader,
                      }}
                    />

                    <Divider />
                  </Card>
                )} /> */}

                <Route path='/app/usersettings/changetheme' component={SettingsThemesContainer} />

              </Grid>


            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    )
  }
}

const styles: any = (theme: any) => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#f9fcff'
    //overflow: 'hidden',
    //background: `url(${backgroundShape}) no-repeat`,
    //backgroundSize: 'cover',
    //backgroundPosition: '0 400px',
    //paddingBottom: 200
  },
  grid: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 20px)'
    }
  },
  cardHeader: {
    color: theme.palette.text.primary,
    fontSize: 18
  },
  paper: {
    padding: theme.spacing.unit * 3,
    textAlign: 'left',
    color: theme.palette.text.secondary,
    minHeight: 600
  },
  rangeLabel: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: theme.spacing.unit * 2
  },
  topBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 32
  },
  outlinedButtom: {
    textTransform: 'uppercase',
    margin: theme.spacing.unit
  },
  actionButtom: {
    textTransform: 'uppercase',
    margin: theme.spacing.unit,
    width: 152
  },
  blockCenter: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center'
  },
  block: {
    padding: theme.spacing.unit * 2,
  },
  box: {
    marginBottom: 40,
    // height: 140
  },
  inlining: {
    display: 'inline-block',
    marginRight: 10
  },
  buttonBar: {
    display: 'flex'
  },
  alignRight: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  noBorder: {
    borderBottomStyle: 'hidden'
  },
  loadingState: {
    opacity: 0.05
  },
  loadingMessage: {
    position: 'absolute',
    top: '40%',
    left: '40%'
  },
  sample: {
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      marginBottom: 72,
    }
  },
  marginbottom72: {
    marginBottom: 20,
    fontWeight: 600
  },
  boxcontent: {
    width: 420,
    height: 720,
    backgroundColor: '#ffffff',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'calc(100% - 80%)',
      width: '60%'
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 'calc(100% - 94%)',
      width: '90%'
    }
  }
});


export default withStyles(styles)(UserSettingsComponent);