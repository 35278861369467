import { Dispatch } from 'redux';
import Storage from '../../shared/utils/Storage';
import {
  API,
  ENDPOINT
} from '../../constants/api';
import {
  types,
  ILoginUser,
  ILoginReceived,
  ILoginSuccess,
  ISetLoader,
  LoginFormRequestData,
  ILoginFormState
} from './model';
import Action from '../IAction';
import { restPost, loaderEvent, toastEvent } from '../common/actions';
import { ActionType } from 'typesafe-actions';
const API_URL = API.BASE_API + ENDPOINT.LOGIN_URL;
import axios from 'axios';

export function login(loginUser: {}): Action<ILoginUser> {
  return {
    type: types.LOGIN,
    payload: {
      loginUser
    }
  }
}

export function loginReceived(data: ILoginReceived): Action<ILoginReceived> {
  return {
    type: types.LOGIN_RECEIVED,
    payload: {
      user: data.user
    }
  }
}

// export function isUserAuthenticated(dispatch: Dispatch<ActionType<any>>) {
//   var jwt = Storage.getJWT();
//   //if(userData && userData.jwt != undefined && userData.jwt != null) {
//   dispatch(loginSuccess(true)); // set login success flag to true
//   //}
// }

export function loginSuccess(success: boolean): Action<ILoginSuccess> {
  return {
    type: types.LOGIN_SUCCESS,
    payload: {
      isSuccess: success
    }
  }
}

export function setLoading(loading: boolean): Action<ISetLoader> {
  return {
    type: types.SET_LOGIN_LOADING,
    payload: {
      loading
    }
  };
}


export function loginWithEmail(userData: any) {
  var userModel: LoginFormRequestData = {
    username: userData.username,
    password: userData.password
  }

  //After calling API pass data to update reducer (STORE)
  return async (dispatch: any) => {
    console.log(userModel);
    var promise = axios.post(`https://localhost:5001/api/Auth`, userModel);

    promise.then((response: any) => {
      console.log(response);
    });

    // return new Promise<any>((resolve, reject) => {
    //   dispatch(setTopics(data));
    // });

  }
}


// export function requestLoginDetails(data: LoginFormRequestData) {
//   console.log("Requested for login data");
//   const reqData = {
//     data: {
//       email: data.userId,
//       password: data.password
//     }
//   };
//   console.log("ggvg");
//   console.log(reqData);
//   return (dispatch: Dispatch<ActionType<any>>) => {
//     dispatch(loaderEvent(true));
//     const promise = restPost(API_URL, reqData);

//     promise.then(data => {
//       console.log(data);
//       dispatch(loaderEvent(false));
//     }).catch(error => {
//       dispatch(loaderEvent(false));
//       dispatch(toastEvent(error.message, true));
//     });

//   }
// }

// export function requestOtpInit(data) {
//   const reqData = {
//     cmd: "otp-init",
//     data: {
//       type: data.type,
//       otpId: data.otpId,
//       communicationType: data.device
//     }
//   }
//   return dispatch => {
//     dispatch(loaderEvent(true));
//     const promise = rest(API_URL, reqData, false);
//     promise.then(data => {
//       dispatch(loaderEvent(false));
//       dispatch(toastEvent(data.message, true));
//       dispatch(redirectEvent('/menu'));
//     }).catch(error => {
//       dispatch(loaderEvent(false));
//       dispatch(toastEvent(error.message, true));
//     });
//   }

// }

// export function requestOtpResend(data) {
//   const reqData = {
//     cmd: "otp-resend",
//     data: {
//       type: data.type,
//       otpId: data.otpId,
//       communicationType: data.device
//     }
//   }

//   return dispatch => {
//     dispatch(loaderEvent(true));
//     const promise = rest(API_URL, reqData, false);
//     promise.then(data => {
//       dispatch(loaderEvent(false));
//       dispatch(toastEvent(data.message, true));
//     }).catch(error => {
//       dispatch(loaderEvent(false));
//       dispatch(toastEvent(error.message, true));
//     });
//   }


// }

// export function requestOtpVerify(data) {
//   const reqData = {
//     cmd: "otp-verify",
//     data: {
//       otpId: data.otpId,
//       communicationType: data.device,
//       otpCode: data.otpCode
//     }
//   }
//   return dispatch => {
//     dispatch(loaderEvent(true));
//     const promise = rest(API_URL, reqData, false);
//     promise.then(data => {
//       dispatch(loaderEvent(false));
//       dispatch(toastEvent(data.message, true));
//       dispatch(redirectEvent('/changepassword'));
//     }).catch(error => {
//       dispatch(loaderEvent(false));
//       dispatch(toastEvent(error.message, true));
//     });
//   }
// }

